import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Row, } from 'reactstrap';
import { CMSHomeSubCategory } from './cms.home.subcategory';
import { Can, useAuth } from '../user/access/user.permissions';
import { ToolbarManager } from '../cms/common/toolbar.manager';
import { algorithms } from '../common/algorithms';

const VALUES = ["all"]

const TitleComponent = ({ toggleReadMore }) => <Fragment>
    <Button name='default' onClick={toggleReadMore}>
        <ArrowBackIcon /> Go back to home
    </Button>
</Fragment>

/**
 * List all home subcategory
 * 
 * @author Isaac S. Mwakabira
 */
export const ListHomeSubcategory = ({
    category, handleClick,
    classes, general,
    ...props
}) => {
    const [filters, setFilters] = useState(VALUES)
    const [toggleMore, setToggleMore] = useState(false)
    const [readMoreSection, setReadMoreSection] = useState(null)

    /**
     * Capitalize first letter of this word
     */
    const capitalize = (characters) => {
        return characters && characters[0].toUpperCase() + characters.slice(1);
    }

    // get the logged in user
    const auth = useAuth()

    const toggleReadMore = e => setToggleMore(!toggleMore)

    const handleCategoryClick = e => {
        const sectionToReadMore = category?.subCategories?.filter(section => {
            return section._id === e.currentTarget.id
        })[0] // filter only one
        setReadMoreSection(sectionToReadMore)
        toggleReadMore(e) // change view
    }

    return (
        <Fragment>
            <ToolbarManager
                filters={filters} 
                setFilters={setFilters} 
                handleClick={handleClick}
                classes={classes}
                hasTitle={toggleMore}
                titleComponent={() => <TitleComponent toggleReadMore={toggleReadMore} />}
            >
                <Can I='create' a='Category'>
                    <Fragment>
                        <Tooltip title="Create new home item">
                            <Button
                                name="create"
                                onClick={e => handleClick(e)}
                            >
                                <AddIcon /> Create new section
                            </Button>
                        </Tooltip>
                    </Fragment>
                </Can>
            </ToolbarManager>

            <div className='app-sections' style={{ marginTop: '-55px' }}>
                <Row>{
                    !general?.isLoading ? (
                        !toggleMore ? (<Fragment>
                            {category?.subCategories
                                ?.filter(object => {
                                    // Show only those that are published 
                                    // if the authenticated account is not the one which created the object
                                    const published = object?.isPublished
                                    const objectName = capitalize(object?.name)
                                    const authRoles = Object.keys(auth?.user.roles)[0]

                                    // GIS can only be managed by admin and publisher roles
                                    if (objectName === 'Gis' && authRoles === 'writer') {
                                        return false
                                    } 
                                    // draft and not author and is writer
                                    if (!published && auth?.user._id !== object.createdBy&&authRoles==='writer') {
                                        return false
                                    }
                                    return true
                                })
                                .filter(item => algorithms.filterByProperty(filters, item))
                                .map((section, index) => {
                                    /**
                                    * Making sure this main section does not appear
                                    * on the home component. To avoid being edited as its values
                                    * are hard coded in the frontend
                                    */
                                    if (section.name === 'Information for Mini-Grid Developers') {
                                        return null;
                                    }

                                    return <CMSHomeSubCategory 
                                        { ...props }
                                        key={index} 
                                        section={section} 
                                        handleClick={handleClick}
                                        toggleMore={toggleMore}
                                        toggleReadMore={toggleReadMore}
                                        handleCategoryClick={handleCategoryClick}
                                    />
                                })
                            }
                        </Fragment>) 
                        : (
                            <CMSHomeSubCategory 
                                section={readMoreSection} 
                                toggleMore={toggleMore} 
                                handleClick={handleClick}
                                { ...props }
                                key={readMoreSection?._id + 'key'} 
                                toggleReadMore={toggleReadMore}
                                handleCategoryClick={handleCategoryClick}
                            />
                        ) 
                    ) : <div style={{ marginTop: `40px` }} className="loader" />
                }</Row>
            </div>
        </Fragment>
    );
}


ListHomeSubcategory.propTypes = {
    classes: PropTypes.object.isRequired,
}

const homeStyles = theme => ({
    actions: {
        color: theme.palette.text.secondary,
    },
    margin: {
        margin: '0em',
    },
    background: {
        color: '#eeefff',
    },
    title: {
        flex: '0 0 auto',
    }
})

export default withStyles(homeStyles)(ListHomeSubcategory);