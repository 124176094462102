import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddAccountIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import UserProfile from './user.profile';
import { Button, Checkbox, Chip } from '@material-ui/core';
import { ToolbarManager } from '../cms/common/toolbar.manager';
import ConfirmActionManager from '../common/alerts/confirmation.alert';
import { Can } from './access/user.permissions';

/**
 * Enhanced table head
 * 
 * @author Isaac S. Mwakabira
 */
export class EnhancedTableHead extends React.Component {
    rows = [
        { id: 'date', numeric: false, disablePadding: true, label: 'Date Created' },
        { id: 'fullname', numeric: true, disablePadding: false, label: 'Fullname' },
        { id: 'username', numeric: true, disablePadding: false, label: 'Username' },
        { id: 'userroles', numeric: true, disablePadding: false, label: 'Roles' },
    ];
            
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };
  
    render() {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, showActions } = this.props;

        // get logged in user
        const auth = UserProfile.get();

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        {
                            numSelected > 1 && showActions({ user: auth }) ? (
                                <Checkbox
                                    indeterminate={numSelected > 0 && numSelected < rowCount}
                                    checked={numSelected === rowCount}
                                    onChange={onSelectAllClick}
                                />
                            ) : null
                        }
                    </TableCell>
                    {
                        this.rows.map(row => (
                            <TableCell
                                key={row?.id} align={row?.numeric ? 'right' : 'left'}
                                padding={row?.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row?.id ? order : false}
                            >
                                <Tooltip
                                    title={row?.id !== 'userroles' ? `Sort by ${ row?.id }` : "Sort"}
                                    placement={row?.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row?.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row?.id)}
                                        disabled={ row.label === 'Roles' ? true : false }
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        ),
                        this,)
                    }
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

/**
 * Users table toolbar - User accounts list header menu composition
 * 
 * @author Isaac S. Mwakabira
 */
class EnhancedTableToolbar extends React.Component {
    state = {
        open: false,
        isAlertOpen: false
    };

    handleToggle = () => {
        this.setState(state => ({ open: !state.open }));
    };

    handleClose = event => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }
        this.setState({ open: false });
    };

    handleAlertIsOpen = (e, toggledState) => {
        e.preventDefault()
        this.setState({ isAlertOpen: toggledState })
    }

    handleAlertConfirm = e => {
        e.preventDefault()
        // get logged in user
        const auth = UserProfile.get();
        if (auth) {
            if (this.props.numSelected > 1) {
                alert("Failed to delete the selected accounts")
                this.handleAlertIsOpen(e, !this.state?.isAlertOpen)
                throw new Error("Method not implemened")
            } else {
                // call the redux action: deleteAccount(id, token)
                return this.props?.handleAccountDelete(
                    this.props.selectedAccount?._id, 
                    auth?.token
                )
            }
        }
        
        this.handleAlertIsOpen(e, !this.state?.isAlertOpen)
    }

    render() {
        const { 
            handleAccountClick, 
            handleEditProfileClick,
            numSelected, classes, 
            selectedAccount, showActions
        } = this.props;
        const { open } = this.state;
        // get logged in user
        const auth = UserProfile.get();

        return <Fragment>
            <ConfirmActionManager 
                alertMessage={() => {
                    return numSelected > 1 ? <Fragment>
                        <b>{
                            numSelected + ' accounts have been selected for deletion'
                        }</b>. Owners of these user accounts will no longer have access to the system.
                    </Fragment> : <Fragment>
                            Are you sure you want to delete <b>{
                                `${ selectedAccount?.username }'s account`
                            }</b>? You will not be able to restore this user account later.
                        </Fragment>
                }}
                cancelButtonText="Cancel deletion"
                confirmButtonText="Confirm deletion"
                isAlertOpen={this.state?.isAlertOpen} 
                isLoading={this.props.isLoading}
                handleAlertCancel={e => this.handleAlertIsOpen(e, !this.state.isAlertOpen)}
                handleAlertConfirm={this.handleAlertConfirm}
            />
            <ToolbarManager
                classes={classes}
                filters={this.props?.filters}
                setFilters={this.props?.handleFilters}
                hasTitle={false}
                page={this.props?.link}
                titleComponent={() => <Fragment>
                    {
                        numSelected > 0 ? (
                            <Typography style={{ marginLeft: '0.5em'}} color="inherit" variant="subtitle1">
                                {
                                    numSelected >= 1 && !showActions({user:auth}) ? 
                                        `${auth.username + ', Admins only allowed to perform this action'}` 
                                        : numSelected > 1 
                                            ? <strong>
                                                <Chip 
                                                    label={`${numSelected + ' accounts selected for deletion'}`} 
                                                    color='secondary' 
                                                />
                                            </strong> 
                                            : null
                                } 
                            </Typography>
                        ) : (<Typography variant="h6" id="tableTitle"></Typography>)
                    }
                </Fragment>}
                divider={true}
                placeholder={`Filter by roles...`}
                {...this.props}
            >
                {
                    numSelected > 0 ? (
                        <Fragment>
                            {
                                showActions({ user: auth }) ? (
                                    <Fragment>
                                        {
                                            numSelected === 1 ? (
                                                <Fragment>
                                                    <Can I='update' a='User'>
                                                        <Tooltip title="Edit account">
                                                            <Button 
                                                                name="edit" 
                                                                aria-label="Edit account" 
                                                                onClick={ (e) => handleAccountClick(e) }
                                                                id={selectedAccount._id}
                                                            >
                                                                <EditIcon /> Edit account
                                                            </Button>
                                                        </Tooltip>
                                                    </Can>
                                                    <Can I='delete' a='User'>
                                                        <Tooltip title="Delete account">
                                                            <Button 
                                                                aria-label="Delete account"
                                                                variant='contained'
                                                                color='secondary'
                                                                onClick={e => this.handleAlertIsOpen(e, !this.state.isAlertOpen)}
                                                                id={selectedAccount._id}
                                                            >
                                                                <DeleteIcon /> Delete account
                                                            </Button>
                                                        </Tooltip>
                                                    </Can>
                                                </Fragment>
                                            ) : <Can I='delete' a='User'>
                                                <Tooltip title="Delete accounts">
                                                    <Button 
                                                        aria-label="Delete"
                                                        variant='contained'
                                                        color='secondary'
                                                        onClick={e => this.handleAlertIsOpen(e, !this.state.isAlertOpen)}
                                                    >
                                                        <DeleteIcon /> Delete accounts
                                                    </Button>
                                                </Tooltip>
                                            </Can>
                                        }
                                    </Fragment>
                                ) : null
                            }
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Can I='update' a='User'>
                                <Tooltip title="Loggedin account">
                                    <Button 
                                        aria-label="Loggedin account" 
                                        buttonRef={ node => {
                                                this.anchorEl = node
                                            }
                                        }
                                        name="edit"
                                        id={ auth._id }
                                        value={ auth._id }
                                        aria-owns={open ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={ (e) => handleEditProfileClick(e) }
                                    >
                                        <PersonIcon /> View your account
                                    </Button>
                                </Tooltip>
                            </Can>
                            <Can I='create' a='User'>
                                <Tooltip title="Add new accounts">
                                    <Button 
                                        aria-label="Add new accounts" 
                                        buttonRef={ node => {
                                                this.anchorEl = node
                                            }
                                        }
                                        name="create"
                                        aria-owns={open ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={ (e) => handleAccountClick(e) }
                                    >
                                        <AddAccountIcon /> Create new Account
                                    </Button>
                                </Tooltip>
                            </Can>
                        </Fragment>
                    )
                }
            </ToolbarManager>
        </Fragment>
    }
}

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
};

const menuStyles = theme => ({
    root: {
      display: 'flex',
      paddingRight: theme.spacing.unit,
    },
    paper: {
      marginRight: theme.spacing.unit * 2,
    },
    highlight: theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
        },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
});

export default withStyles(menuStyles)(EnhancedTableToolbar);