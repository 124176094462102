import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Divider, Toolbar } from "@material-ui/core";
import FilterManager from "./filter.manager";

/**
 * Toolbar Manager
 *
 * @author Isaac S. Mwakabira
 */
export const ToolbarManager = ({
  filters,
  setFilters,
  classes,
  divider = true,
  hasTitle = false, // if true hide filter layout and show titleComponent. default value is false
  titleComponent: TitleComponent,
  page,
  placeholder,
  ...props
}) => {
  return (
    <Fragment>
      <Toolbar className="section-toolbar">
        <FilterManager
          classes={classes}
          filters={filters}
          setFilters={setFilters}
          hasTitle={hasTitle}
          page={page}
          placeholder={placeholder}
        >
          <TitleComponent />
        </FilterManager>
        <div className={classes.actions}>{props.children}</div>
      </Toolbar>
      {divider && <Divider />}
    </Fragment>
  );
};

ToolbarManager.propTypes = {
  titleComponent: PropTypes.instanceOf(HTMLElement || React.Node),
  classes: PropTypes.object.isRequired,
  filters: PropTypes.array,
  setFilters: PropTypes.func.isRequired,
};
