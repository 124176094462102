/**
 * Sorting data structures
 *
 * @author Isaac S. Mwakabira
 */
export const algorithms = (() => {
  const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  };

  const sort = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  };

  return {
    desc,
    sort,
    stableSort,
    filterByProperty: (filters = [], item) => {
      if (filters?.length === 0) return true;
      if (filters?.includes("all".toLowerCase())) return true;

      const keys = Object.keys(item).map((key) => {
        if (key === "isDraft") return "draft";
        if (key === "isPublished") return "published";
        return key;
      });
      const values = Object.values(item);
      return keys?.some((_key, index) => {
        let key = _key.toLowerCase();
        if (
          filters?.map((filter) => filter.toLowerCase())?.includes(key) &&
          key === "draft"
        )
          return values[index] ? true : false;
        if (
          filters?.map((filter) => filter.toLowerCase())?.includes(key) &&
          key === "published"
        )
          return values[index] ? true : false;
        return false;
      });
    },
    filterByUserAbility: (currObject, auth) => {
      // Show only those that are published
      // hide if the authenticated account is not the one which created the object
      const published = currObject?.isPublished;
      const authRoles = Object.keys(auth?.user.roles)[0];
      // draft and not author and is writer
      if (
        !published &&
        auth?.user._id !== currObject.createdBy &&
        authRoles === "writer"
      ) {
        return false;
      }
      return true;
    },
  };
})();
