import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Divider, Tooltip, withStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
// import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import styles from "../contact/form.styles";
import { Can, useAuth } from "../user/access/user.permissions";
import { ToolbarManager } from "../cms/common/toolbar.manager";
import { algorithms } from "../common/algorithms";
import MainCategoryList from "../library/categories.tabs";

const VALUES = ["all"];

/**
 * List all frequently asked questions
 *
 * @author Isaac S. Mwakabira
 */
const ListFAQS = ({ general, questions, handleClick, classes }) => {
  const [filters, setFilters] = useState(VALUES);
  const [selected, setSelected] = useState(null);
  const auth = useAuth();

  useEffect(() => {
    if (questions?.subCategories?.length > 0) {
      setSelected(questions?.subCategories[0]);
    }
  }, [questions]);

  const handleSelected = (selectedCategory) => {
    setSelected(selectedCategory);
  };

  return (
    <Fragment>
      <ToolbarManager
        filters={filters}
        setFilters={setFilters}
        handleClick={handleClick}
        classes={classes}
        divider={false}
      >
        {/* <Can I="update" a="Category">
          <Fragment>
            <Tooltip title="Manage category of questions">
              <Button name="edit" onClick={(e) => handleClick(e)}>
                <EditOutlinedIcon /> Manage categories
              </Button>
            </Tooltip>
          </Fragment>
        </Can> */}
        <Can I="create" a="Category">
          <Fragment>
            <Tooltip title="Create new question">
              <Button name="create" onClick={(e) => handleClick(e)}>
                <AddIcon /> Create new question
              </Button>
            </Tooltip>
          </Fragment>
        </Can>
      </ToolbarManager>

      {general?.isLoading && selected===null ? null : (
        <div style={{ marginLeft: "15px", marginRight: "15px" }}>
          <div className={classes.title}>
            <div className="filters">
              <Fragment>
                <span style={{ marginRight: "0.5em" }}>Categories</span>
                <span>
                  <MainCategoryList
                    categories={questions?.subCategories}
                    selected={selected}
                    handleSelected={handleSelected}
                  />
                </span>
              </Fragment>
            </div>
          </div>
          <div className={classes.actions}></div>
        </div>
      )}

      <Divider style={{ marginTop: "0.5em" }} />

      <ul>
        {!general?.isLoading ? (
          // if this category has questions render, else don't
          selected?.subCategories.filter((object) => {
              // Show only those that are published
              // hide if the authenticated account is not the one which created the object
              const published = object?.isPublished;
              const authRoles = Object.keys(auth?.user.roles)[0];
              // draft and not author and is writer
              if (
                !published &&
                auth?.user._id !== object.createdBy &&
                authRoles === "writer"
              ) {
                return false;
              }
              return true;
            })
            .filter((item) => algorithms.filterByProperty(filters, item))
            .map(({ name, _id }, index) => {
              return (
                <li key={index}>
                  <Can I="update" a="Category" passThrough>
                    <a
                      name="edit"
                      id={_id}
                      key={_id}
                      href={`${"/faqs/" + name}`}
                      onClick={(e) => handleClick(e)}
                    >
                      {name}
                    </a>
                  </Can>
                </li>
              );
            })
        ) : (
          <div className="loader" />
        )}
      </ul>
    </Fragment>
  );
};

ListFAQS.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListFAQS);
