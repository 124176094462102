import React, { Fragment, useState } from "react";
import "./library.css";
import { withStyles, Tooltip, Button, Divider } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import styles from "../contact/form.styles";
import { ToolbarManager } from "../cms/common/toolbar.manager";
import { Can, useAuth } from "../user/access/user.permissions";
import { algorithms } from "../common/algorithms";
import MainCategoryList from "./categories.tabs";

const VALUES = ["all"];

/**
 * List all documents by category groups
 *
 * @author Isaac S. Mwakabira
 *
 */
const ListLibraryDocuments = ({
  classes,
  general,
  handleClick,
  fetchCategoryDocs,
  sub_cate_documents,
  maincategory: resources,
  filteredResource,
  handleFilteredResource,
}) => {
  const [filters, setFilters] = useState(VALUES);
  const [selectedCategory, setSelectedCategory] = useState();
  // authenticated user
  const auth = useAuth();

  useState(() => {
    // if resources not null
    if (resources?.subCategories?.length > 0) {
      // first subcategory in the library main category
      // fetch its documents
      const defaultCategory = resources?.subCategories[0]
      setSelectedCategory(filteredResource ? filteredResource : defaultCategory);
    }
  }, [resources, selectedCategory]);

  const handleSelected = (item) => {
    // then iterate through the subcategories
    // and filter the chosen section
    const filteredResource = resources?.subCategories?.filter((resource) => {
      if (item !== null && resource !== null) {
        // check if the chosen resource from the drop down list
        // equals one of the resources/subCategories
        // in Library
        if (resource._id === item._id) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });

    // was anything returned
    if (filteredResource) {
      // fetch its documents
      fetchCategoryDocs(filteredResource[0]?._id);
      handleFilteredResource(filteredResource[0])
    }
  }

  const handleFilterChange = (filters) => {
    setFilters(filters);
  };

  return (
    <Fragment>
      <ToolbarManager
        filters={filters}
        setFilters={handleFilterChange}
        handleClick={handleClick}
        classes={classes}
        divider={false}
      >
        <Can I="create" a="Category">
          <Fragment>
            <Tooltip title="Create new document">
              <Button name="create" onClick={(e) => handleClick(e)}>
                <AddIcon /> New document
              </Button>
            </Tooltip>
          </Fragment>
        </Can>
      </ToolbarManager>

      <div style={{ marginLeft: "15px", marginRight: "15px" }}>
        <div className={classes.title}>
          <div className="filters">
            <Fragment>
              {resources?.subCategories && (
                <span style={{ marginRight: "0.5em" }}>Categories</span>
              )}
              <span>
                <MainCategoryList
                  categories={resources?.subCategories}
                  selected={selectedCategory}
                  handleSelected={handleSelected}
                />
              </span>
            </Fragment>
          </div>
        </div>
        <div className={classes.actions}></div>
      </div>

      <Divider style={{ marginTop: "0.5em" }} />

      <div style={{ marginLeft: "15px", marginRight: "15px" }}>
        {!general?.isLoading ? (
          <Fragment>
            <ul>
              {sub_cate_documents?.length > 0 ? (
                sub_cate_documents
                  ?.filter((object) => {
                    // Show only those that are published
                    // hide if the authenticated account is not the one which created the object
                    const published = object?.isPublished;
                    const authRoles = Object.keys(auth?.user?.roles)[0];
                    // draft and not author and is writer
                    if (
                      !published &&
                      auth?.user?._id !== object?.createdBy &&
                      authRoles === "writer"
                    ) {
                      return false;
                    }
                    return true;
                  })
                  ?.filter((document) =>
                    algorithms.filterByProperty(filters, document)
                  )
                  ?.map((document, index) => {
                    return (
                      <Fragment key={document.name}>
                        <li id={index} key={document.name}>
                          <Can I="update" a="Category" passThrough>
                            <a
                              href={`${"/library/" + document.name}`}
                              onClick={(e) => handleClick(e)}
                              name="edit"
                              id={document._id}
                            >
                              {document.name}
                            </a>
                          </Can>
                        </li>
                      </Fragment>
                    );
                  })
              ) : (
                <p>No documents found</p>
              )}
            </ul>
          </Fragment>
        ) : (
          <div style={{ marginTop: `50px` }} className="loader" />
        )}
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(ListLibraryDocuments);
