import React, { Fragment } from 'react';
import { Card, CardBody } from 'reactstrap'
import Panel from '../financing/panel';
import { NoDataCard } from '../card.text';
import { Intent } from '@blueprintjs/core';
import CustomColumn from '../news/custom.column';

const text = "The process for requesting Minigrid Related Licensing - Generation and Distribution, entails the following steps. Further details are available by clicking on each step.";

const styles = {
    marginLeft: '0px'
}

/**
 * Licensing 
 * 
 * @author Paul Sembeleka(Pablo)
 * @author Isaac S. Mwakabira
 */
const Licensing = ({ 
    general,
    maincategory
}) => {
    const renderPanels = ({ subCategories }) => {
        return subCategories
        ?.filter(item => item?.isPublished)
        ?.map(({ name, about }, index) => {
            return <Panel 
                key={index} 
                heading={`${(index + 1) + '. ' + name}`} 
                text={about} 
            />
        });
    }

    return (
        <Fragment>
            {
                maincategory ?
                <Card>
                    <NoDataCard
                        text={text} header={`Licensing`}
                        intent={Intent.PRIMARY}
                        style={styles}
                    />
                    <CardBody>
                        {
                            !general?.isLoading ? (
                                <Fragment>
                                    { maincategory && renderPanels(maincategory) }
                                </Fragment>
                            ) : <div style={{ marginTop: `50px` }} className="loader" />
                        }
                    </CardBody>
                </Card>
                : <CustomColumn sm='12' md='12' lg='12'>
                    {
                        !general?.isLoading ? (
                            <Card>
                                <NoDataCard
                                    text={`No information availble to show. Please check your device internet connection and refresh.`}
                                    header={`Information!`}
                                    intent={Intent.WARNING}
                                />
                            </Card>
                        ) : <div style={{ marginTop: `50px` }} className="loader" />
                    }
                </CustomColumn>
            }
        </Fragment>
    )
}

export default Licensing;
