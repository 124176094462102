import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { reduxForm } from 'redux-form';
import AsyncValidate from '../contact/form.async-validate';
import Validate from '../contact/email.validate';

import { Button, RadioGroup, Radio } from '@blueprintjs/core';
import styles from '../contact/form.styles';
import { DefaultMuiFormFileinputField } from '../forms/form.fileinput.field';
import UserProfile from '../user/user.profile';
import BootstrapGridColumn from '../forms/form.grid.column';
import { BootsrapTextField } from '../forms/form.bootstrap.field';
import { BootsrapTextareaField } from '../forms/form.textarea.field';
import Toast from '../../toastfy';
import { Can } from '../user/access/user.permissions';
import ConfirmActionManager from '../common/alerts/confirmation.alert';

/**
 * @author Isaac S. Mwakabira
 * 
 */
class EditLibraryItem extends Component {

    constructor() {
        super();
        this.state = {
            isAlertOpen: false,
            radio: 'isDraft'
        }

        /**
         * Bind events to each Function, so that they can be passed without args 
         * i.e this.handleChange
         * 
         */
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleRadioChange = (e) => {
        this.setState({ radio: e.target.value })
    }

    handleToggleChecked = e => this.setState({
        ...this.state, 
        isDraft: e.checked
    })

    filterCategory = (document_id) => {
        const { maincategory } = this.props;
        return maincategory?.subCategories.map(category => {
            // for each category
            // search if this id belongs to one of them and
            // return this category
            const documents = category.documents;
            const matched = documents.filter(d_id => d_id === document_id);
            if (matched) {
                return category
            } else {
                return null
            }
        })
    }

	/**
	 * On change, update the app's React state with event type value.
	 *
	 * @param {Event} event
	 */
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target === 'files' ? event.target.files[0] : event.target.value
        });
    }

    handleSubmit = (values) => {
        // prevent default behaviour
        const { name, summary, radio } = this.state;
        // category under which this documnet falls
        const { document, userCanPublish } = this.props;
        // get authenticated user token
        const user = UserProfile.get();
        if (user?.token) {
            if (name || summary || values || radio) {
                // define sub-category structure
                const data = {
                    name: name,
                    description: summary,
                    file: values?.supporting_document,
                    isDraft: radio === 'isDraft' ? true : false
                }

                if (userCanPublish) {
                    data.isPublished= radio === 'isPublished' ? true : false
                }

                this.props.editDocument(
                    data, 
                    user?.token, 
                    document
                );
            }
        }
    }

    archiveDocument = (event) => {
        event.preventDefault();
        // question to be deleted
        const { document, filteredResource } = this.props;
        // if question exists then delete
        if (document?._id) {
            // then get authenticated user token
            const user = UserProfile.get();
            if (user?.token) {
                // filtered resource category has to exist
                if (filteredResource !== null) {
                    this.props.archiveFileDocument(filteredResource, document, user.token);
                } else {
                    Toast.emit({
                        type: Toast.TYPES.INFO,
                        message: "Category does not exist. Please try again!"
                    });
                }
            }
        }
    }

    handleAlertIsOpen = (e, toggledState) => {
        e.preventDefault()
        this.setState({ isAlertOpen: toggledState })
    }

    handleAlertConfirm = e => {
        e.preventDefault()
        // handle deletion here and close dialog box
        this.archiveDocument(e)
        this.handleAlertIsOpen(e, !this.state?.isAlertOpen)
    }

    render() {

        const { 
            classes, 
            handleClick, 
            handleSubmit, 
            document, 
            filteredResource, 
            general,
            userCanPublish 
        } = this.props;

        // state
        const { name, summary, radio } = this.state;

        return (
            <Fragment>
                <h5 className='heading-text'>
                    Edit a library document
                </h5>

                <ConfirmActionManager 
                    alertMessage={() => {
                        return <Fragment>
                            Are you sure you want to delete <b>{
                                `${ document?.name }`
                            }</b>? You will not be able to restore this document later.
                        </Fragment>
                    }}
                    cancelButtonText="Cancel deletion"
                    confirmButtonText="Confirm deletion"
                    isAlertOpen={this.state?.isAlertOpen} 
                    handleAlertCancel={e => this.handleAlertIsOpen(e, !this.state.isAlertOpen)}
                    handleAlertConfirm={this.handleAlertConfirm}
                />

                <form 
                    onSubmit={handleSubmit(values => this.handleSubmit(values))} 
                    autoComplete="off"
                >
                    {
                        !general?.isLoading ? (
                            <Fragment>
                                <div className='margin-fix form-row'>
                                    <BootstrapGridColumn>
                                        <BootsrapTextField
                                            value={filteredResource ? (this.state.category ? this.state.category : filteredResource.name) : ''}
                                            name="category"
                                            disabled={ true }
                                            label='Category'
                                            placeholder="Edit document category..."
                                            handleChange={this.handleChange}
                                        />
                                    </BootstrapGridColumn>
                                    <BootstrapGridColumn>
                                        <BootsrapTextField
                                            value={document ? (this.state.name ? this.state.name : document.name) : ''}
                                            name="name"
                                            label='Name'
                                            placeholder="Edit document name..."
                                            handleChange={this.handleChange}
                                        />
                                    </BootstrapGridColumn>
                                </div>

                                <div className="form-group">
                                    <BootsrapTextareaField
                                        name="summary"
                                        value={document ? (this.state.summary ? this.state.summary : document.description) : ''}
                                        placeholder="Edit document summary..."
                                        label="Summary Text"
                                        type="text"
                                        rows={5}
                                        handleChange={this.handleChange}
                                    />
                                </div>

                                <div className="margin-fix form-row" style={{ width: `30%` }}>
                                    <BootstrapGridColumn>
                                        <DefaultMuiFormFileinputField
                                            id="pdf_document"
                                            placeholder="Upload PDF Document*"
                                            classes={classes}
                                            name='supporting_document'
                                            handleFileChange={this.handleChange}
                                        />
                                    </BootstrapGridColumn>
                                </div>
                            </Fragment>
                        ) : <div style={{ marginTop: `50px` }} className="loader" />
                    }

                    <div className={classes.margin} />
                    <div className={classes.margin} />

                    <RadioGroup
                        label="Publication Status*"
                        onChange={this.handleRadioChange}
                        selectedValue={radio}
                    >
                        <Radio 
                            label='Draft' 
                            value="isDraft" 
                        />
                        <Radio 
                            label='Published' 
                            value="isPublished" 
                            disabled={userCanPublish ? false : true}
                        />
                    </RadioGroup>

                    <Button
                        icon='new-object'
                        type="submit"
                        disabled={!(name || summary || radio)}
                        intent="success"
                        text="Save document"
                    />

                    <Can I='delete' a='File'>
                        <Button
                            icon='delete'
                            intent='danger'
                            text="Delete document"
                            name="archive"
                            className={classes.margin}
                            onClick={(e) => this.handleAlertIsOpen(e, !this.state.isAlertOpen)}
                        />
                    </Can>

                    <Button
                        icon='arrow-left'
                        name="default"
                        intent="primary"
                        text="Cancel"
                        onClick={e => handleClick(e)}
                    />
                </form>
            </Fragment>
        );
    }
}

EditLibraryItem.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default reduxForm({
    form: 'editLibraryDocument',
    Validate,
    AsyncValidate
})(withStyles(styles)(EditLibraryItem));