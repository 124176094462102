import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing.unit * 0,
    margin: 0,
  },
  chip: {
    margin: theme.spacing.unit * 0.2
  },
})

function MainCategoryList({ 
  classes, 
  categories = [], 
  selected, 
  handleSelected
}) {
  const [selectedChip, setSelectedChip] = React.useState()

  useEffect(() => {
    if (categories?.length > 0) {
      setSelectedChip(selected)
    }
  }, [selected, categories])

  const handleSelectedCategory = (curr) => {
    setSelectedChip(curr)
    handleSelected(curr)
  }

  return (
    <Paper elevation={0} component="ul" className={classes.root}>
      {categories?.map((data) => {
        const { isPublished } = data
        return (
          <li key={data._id}>
            <Chip
              id={data._id}
              size="small"
              color={selectedChip?._id === data?._id ? "primary" : "default"}
              variant={selectedChip?._id === data?._id ? "default" : "outlined"}
              icon={isPublished ? <DoneIcon /> : null}
              label={data.name}
              clickable
              onClick={()=>handleSelectedCategory(data)}
              className={classes.chip}
            />
          </li>
        );
      })}
    </Paper>
  );
}

export default withStyles(useStyles, { withTheme: true })(MainCategoryList)