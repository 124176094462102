import React, { Fragment } from "react";
import { Alert, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";

export const ConfirmActionManager = ({
  alertMessage: AlertMessage,
  cancelButtonText,
  confirmButtonText,
  isAlertOpen = false,
  icon = "trash",
  handleAlertCancel,
  handleAlertConfirm,
  isLoading= false
}) => {
  return (
    <Fragment>
      <Alert
        cancelButtonText={cancelButtonText}
        confirmButtonText={confirmButtonText}
        icon={icon}
        intent={Intent.DANGER}
        isOpen={isAlertOpen}
        loading={isLoading}
        onCancel={handleAlertCancel}
        onConfirm={handleAlertConfirm}
      >
        <AlertMessage />
      </Alert>
    </Fragment>
  );
};

ConfirmActionManager.propTypes = {
  // alertMessage: PropTypes.instanceOf(HTMLElement | React.Node).isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  isAlertOpen: PropTypes.bool,
  icon: PropTypes.string,
  handleAlertConfirm: PropTypes.func.isRequired,
};

export default ConfirmActionManager;
