import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { Callout } from '@blueprintjs/core';
import { IconButton, Tooltip } from '@material-ui/core';
import PencileIcon from '@material-ui/icons/EditSharp';
import Delete from '@material-ui/icons/Delete';
import ConfirmActionManager from '../common/alerts/confirmation.alert';
import { Can, useAuth } from '../user/access/user.permissions';

export const CMSHomeSubCategory = ({
    // props holds state functions like defaultItem(), saveItem() etc 
    section,
    handleCategoryClick,
    toggleReadMore,
    archiveCategory,
    capitalize,
    toggleMore,
    handleClick
}) => {
    const [isAlertOpen, setIsAlertOpen] = useState(false)
    const auth = useAuth()

    const renderReadMore = (section) => {
        if (section?.about.length > 250) {
            return (<span 
                id={section?._id}
                onClick={e => {
                    handleCategoryClick(e)
                    return toggleReadMore(e)
                }} 
                className="badge badge-info" 
                style={{ cursor: 'pointer' }}>
                    Read more...
            </span>)
        } else {
            return ''
        }
    }

    /**
     * Delete category
     */
    const handleArchiveCategory = (event) => {
        event.preventDefault();
        // if section is defined then try to delete
        // authenticated user token
        if (section?._id && auth?.user?.token) {
            archiveCategory(section, auth?.user?.token, capitalize(section?.name));
        }
    }

    const handleAlertIsOpen = (e) => {
        e.preventDefault()
        setIsAlertOpen(!isAlertOpen) // open confirm dialog
    }

    const handleAlertConfirm = e => {
        e.preventDefault()
        handleArchiveCategory(e) // delete this home section
        handleAlertIsOpen(e) // remove confirm dialog
    }

    return (
        <Fragment>
            <ConfirmActionManager 
                alertMessage={() => {
                    return <Fragment>
                        Are you sure you want to delete <b>{
                            `${ section?.name }`
                        }</b>? You will not be able to restore this item later.
                    </Fragment>
                }}
                cancelButtonText="Cancel deletion"
                confirmButtonText="Confirm deletion"
                isAlertOpen={isAlertOpen} 
                handleAlertCancel={handleAlertIsOpen}
                handleAlertConfirm={handleAlertConfirm}
            />

            <Col key={section?.name + section?._id} sm='12' md='12' lg='12'>
                <div style={{ minHeight: 'auto' }}>
                    <div style={{ marginTop: '0.1' }}>
                        <h6 style={{ marginBottom: '0em' }}>
                            <div>
                                <a  className='flex'
                                    name="edit" id={section?._id}
                                    key={section?._id} href="/cms"
                                    onClick={(e) => handleClick(e)}
                                >
                                    <strong>{section?.name}</strong>
                                </a>
                                <Can I='update' a='Category'>
                                    <Tooltip title="Edit item">
                                        <IconButton
                                            name="edit" 
                                            id={section?._id}
                                            onClick={(e) => handleClick(e)}
                                        >
                                            <PencileIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                </Can>
                                <Can I='delete' a='Category'>
                                    <Tooltip title="Delete item">
                                        <IconButton
                                            name="archive" 
                                            disabled={section?.isPublished}
                                            id={section?._id}
                                            onClick={handleAlertIsOpen}
                                        >
                                            <Delete fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                </Can>
                            </div>
                        </h6>
                        <span>{section?.isDraft ? <span className="badge badge-info">Draft</span> : null }</span>
                        <span>{section?.isPublished ? <span className="badge badge-info">Published</span> : null }</span>
                        <Callout>
                            {
                                toggleMore ? (<Fragment>
                                    <span style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{
                                        __html: section?.about
                                    }}/>
                                </Fragment>) : (
                                    <Fragment>
                                        <span style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{
                                            __html: `${section?.about.substring(0, 250)}`
                                        }}/> 
                                        { renderReadMore(section) }
                                    </Fragment>
                                )
                            }
                        </Callout>
                    </div>
                </div>
            </Col>
        </Fragment>
    );
}

CMSHomeSubCategory.propTypes = {
    section: PropTypes.object.isRequired
}