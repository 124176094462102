
export const GisType = {

    FETCH_GIS: "FETCH_GIS",
    FETCH_GIS_FEATURES: "FETCH_GIS_FEATURES",
    CREATE_NEW_GIS_FEATURE: "CREATE_NEW_GIS_FEATURE",
    FETCH_DISTRICTS: "FETCH_DISTRICTS",
    FETCH_DISTRICT: "FETCH_DISTRICT",
    FETCH_DISTRICT_COORDINATES: "FETCH_DISTRICT_COORDINATES",
    FETCH_REGIONS: "FETCH_REGIONS",
    FETCH_REGION: "FETCH_REGION",
    FETCH_MAREP_CENTERS: "FETCH_MAREP_CENTERS",
    FETCH_POLYGON_CENTROID: "FETCH_POLYGON_CENTROID",
    FETCH_DISTRICT_MAREP_CENTERS: "FETCH_DISTRICT_MAREP_CENTERS",
    FETCH_REGION_MAREP_CENTERS:"FETCH_REGION_MAREP_CENTERS",
    FETCH_ESCOM_METERS: "FETCH_ESCOM_METERS",
    FETCH_DISTRIBUTION_LINES: "FETCH_DISTRIBUTION_LINES",
    FETCH_TRANSFORMERS: "FETCH_TRANSFORMERS",
    FETCH_POWER_PLANTS: "FETCH_POWER_PLANTS",
    FETCH_POWER_PLANT_FILTERS: "FETCH_POWER_PLANT_FILTERS",
    FETCH_POWER_SUB_STATIONS: "FETCH_POWER_SUB_STATIONS",
    
}
