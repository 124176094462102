import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Intent, Button, TagInput } from "@blueprintjs/core";

/**
 * Filters
 *
 * @author Isaac S. Mwakabira
 */
export const FilterManager = ({
  filters,
  setFilters,
  hasTitle,
  titleComponet: TitleComponent,
  classes,
  page,
  placeholder = "Filters",
  ...props
}) => {
  const handleChange = (values) => setFilters(values);
  const handleClear = (e) => handleChange(filters?.length > 0 ? [] : filters);

  const clearButton = (
    <Button
      small={true}
      icon={filters?.length > 1 ? "cross" : "refresh"}
      minimal={true}
      onClick={handleClear}
    />
  );

  return (
    <div className={classes.title}>
      <div className="filters">
        {hasTitle ? (
          <Fragment>{props.children}</Fragment>
        ) : (
          <Fragment>
            <span style={{ marginRight: "0.5em" }}>Filter By</span>
            <TagInput
              className="input"
              leftIcon="filter"
              onChange={handleChange}
              placeholder={placeholder}
              rightElement={clearButton}
              tagProps={{
                multiline: false,
                minimal: true,
                rightIcon: undefined,
                intent: Intent.SUCCESS,
              }}
              values={filters}
            />
            {page === "accounts" && props.children}
          </Fragment>
        )}
      </div>
    </div>
  );
};

FilterManager.propTypes = {
  hasTitle: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  filters: PropTypes.array,
  setFilters: PropTypes.func.isRequired,
};

export default FilterManager;
