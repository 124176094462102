import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import { ListItem, List, Divider } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FinancingIcon from "@material-ui/icons/AttachMoney";
import DocumentIcon from "@material-ui/icons/InsertDriveFile";
import GisIcon from "@material-ui/icons/Map";
import LicencingIcon from "@material-ui/icons/Gavel";
import DirectoryIcon from "@material-ui/icons/LocalLibrary";
import NewsIcon from "@material-ui/icons/LibraryBooks";
// import ContactIcon from '@material-ui/icons/ContactMail'
// import AccountIcon from '@material-ui/icons/AccountCircle'
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import HomeIcon from "@material-ui/icons/Home";
import FAQIcon from "@material-ui/icons/QuestionAnswer";
import { useAuth } from "../user/access/user.permissions";

export const drawer_controls = [
  { name: "home", button: <HomeIcon /> },
  { name: "licensing", button: <LicencingIcon /> },
  { name: "financing", button: <FinancingIcon /> },
  { name: "directory", button: <DirectoryIcon /> },
  { name: "library", button: <DocumentIcon /> },
  { name: "gis", button: <GisIcon /> },
  { name: "news", button: <NewsIcon /> },
  { name: "faqs", button: <FAQIcon /> },
];

const config = [
  { name: "accounts", button: <PersonAddIcon /> },
  { name: "logout", button: <LogoutIcon /> },
];

const ListDrawerControls = ({
  items,
  handleLink,
  link,
  classes,
  capitalize,
}) => {
  const auth = useAuth();

  //   console.log({ auth:  });

  return (
    <>
      {items
        ?.filter((item) => {
          if (!item) return null;
          if (
            item?.name === "gis" &&
            Object.keys(auth?.user.roles)[0] !== "admin"
          )
            return false;
          else return true;
        })
        .map(({ name, button }) => (
          <ListItem
            button
            key={name}
            onClick={(e) => handleLink(e, name)}
            className={`${link === name && classes.highlight}`}
          >
            <ListItemIcon>{button}</ListItemIcon>
            <ListItemText primary={capitalize(name)} />
          </ListItem>
        ))}
    </>
  );
};

/**
 * Custom material-ui drawer
 *
 * @author Paul S. Sembereka
 * @author Isaac S. Mwakabira
 *
 * @param {Object} classes
 * @param {Theme} theme
 * @param {Function} handleLink
 * @param {Function} capitalize
 * @param {Function} drawerClose
 *
 */
const CustomDrawer = ({
  classes,
  theme,
  handleLink,
  capitalize,
  drawerClose,
  link,
  homeSubCategories,
}) => {
  const [drawer, setDrawer] = useState([]);

  useEffect(() => {
    setDrawer(
      homeSubCategories
        ?.filter((category) => {
          // Show only those that are published
          return category?.isPublished;
        })
        .map((item) => {
          const itemName = capitalize(item?.name.toLowerCase());
          return drawer_controls.filter((control) => {
            const controlName = capitalize(control.name);
            // Make visible only those created in home_subCategories
            return controlName === itemName;
          })[0];
        })
    );
  }, [capitalize, homeSubCategories]);

  return (
    <Fragment>
      <div className={classes.toolbar}>
        <IconButton onClick={drawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <List>
        {/* // Home drawer list item should always be visible */}
        <ListItem
          button={true}
          key={drawer_controls[0].name}
          onClick={(e) => handleLink(e, drawer_controls[0].name)}
          className={`${link === drawer_controls[0].name && classes.highlight}`}
        >
          <ListItemIcon>{drawer_controls[0].button}</ListItemIcon>
          <ListItemText primary={capitalize(drawer_controls[0].name)} />
        </ListItem>
        <ListDrawerControls
          classes={classes}
          items={drawer}
          link={link}
          handleLink={handleLink}
          capitalize={capitalize}
        />
      </List>

      <Divider />
      <List>
        <ListDrawerControls
          classes={classes}
          items={config}
          link={link}
          handleLink={handleLink}
          capitalize={capitalize}
        />
      </List>
      <Divider />
    </Fragment>
  );
};

// styles
const styles = (theme) => ({
  drawerPaper: {
    position: "relative",
    width: `100%`,
  },
  root: {
    color: green[600],
    "&$checked": {
      color: green[500],
    },
  },
  checked: {},
  button: {
    display: "block",
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    marginBottom: theme.spacing.unit * 2,
    minWidth: "auto",
  },
  legend: {
    marginTop: theme.spacing.unit * 3,
  },
  margin: {
    marginLeft: theme.spacing.unit * 0,
  },
  highlight: {
    borderRight: {
      color: green[600],
    },
  },
});

CustomDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomDrawer);
