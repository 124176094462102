import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Radio, RadioGroup } from '@blueprintjs/core';
import { Paper, FormControl } from '@material-ui/core';
import styles from '../contact/form.styles';
import UserProfile from '../user/user.profile';
import { SelectInputControl } from '../forms/form.selectinput.field';
import { BootsrapTextField } from '../forms/form.bootstrap.field';
import { BootsrapTextareaField } from '../forms/form.textarea.field';
import BootstrapGridColumn from '../forms/form.grid.column';
import CustomCKEditor from '../ckeditor/editor.component';
import ConfirmActionManager from '../common/alerts/confirmation.alert';
import { Can } from '../user/access/user.permissions';

/**
 * Edit a particular question 
 * regardless of which section it belongs to
 * 
 * @author Isaac S. Mwaakabira
 */
class EditQuestion extends Component {
    constructor() {
        super();
        this.state = {
            question: null,
            add_category: false,
            edit_category: false,
            edit_question: true,
            isAlertOpen: false 
        }
    }

    handleRadioChange = (e) => {
        this.setState({ radio: e.target.value })
    }

    componentDidMount() {
        this.setState({ 
            radio: this.props?.question?.isPublished ? 'isPublished' : 'isDraft' 
        })
    }

    /**
     * On event change, get field name and value, set state
     */
    handleTextChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    // choosen section
    handleChange = (e) => {
        // if chosen section
        if (e.target.name) {
            const sectionName = e.target.value;
            const sections = this.props.maincategory;

            // if sections not null
            // then iterate through the subcategories
            // and filter the chosen section
            const filteredSection = sections?.subCategories?.filter(section => {
                // check if the chosen section from the drop down list
                // equals one of the sections/subCategories
                // in the Frequently asked questions
                if (section?.name === sectionName) {
                    return true;
                }
                return false;
            });

            // was anything returned
            if (filteredSection) {
                this.setState({ [e.target.name]: filteredSection[0] });
            }
        }
    }

    setEditorText = (editor) => {
        this.setState({ editorText: editor.getData() });
    }

    handleAddSection = (event) => {
        // prevent default events
        event.preventDefault();
        // if add_category if false
        // then set it to true else false
        this.setState({ 
            add_category: true, 
            edit_category: false,
            edit_question: false 
        })
    }

    handleEditSection = (event) => {
        // prevent default events
        event.preventDefault();
        // if edit_resource if false
        // then set it to true else false
        this.setState({ 
            edit_category: true, 
            add_category: false,
            edit_question: false 
        })
    }

    handleDeleteSection = (event) => {
        // prevent default events
        event.preventDefault();
        // get authenticated user token
        const user = UserProfile.get();
        // proceeed to delete the selected section or category
        this.props.archiveCategory(
            this.state.section,
            user.token,
            this.props.capitalize(this.props.link)
        );
    }

    handleSubmit = (e) => {
        // Prevent default submit action
        e.preventDefault();
        // question
        const { 
            _question, question, radio,
            editorText, shortName, section_name, 
            section_short_name, section_summary 
        } = this.state;

        const { userCanPublish } = this.props

        const emptyQFields = _question || editorText || shortName || radio ? false : true;
        const emptySFields = section_name && section_short_name && section_summary && radio ? false : true;

        // get authenticated user token
        const user = UserProfile.get();
        if (user !== null && user.token !== undefined) {
            let edited_question;
            // check if resource or file if being added
            if (!this.state.add_category) {
                // get edited_question structure
                edited_question = {
                    name: _question,
                    shortName: shortName,
                    about: editorText,
                    isDraft: radio === 'isDraft' ? true : false
                }

                // only users with admin/publisher roles are allowed to edit this property
                if (userCanPublish) {
                    edited_question.isPublished= radio === 'isPublished' ? true : false
                }

                // question defined
                if (!emptyQFields) {
                    // then edit this edited_question
                    this.props.editCategory(
                        question._id,
                        edited_question,
                        user.token,
                        question,
                        this.props.capitalize(this.props.link)
                    );
                }
            } else {
                // adding section
                if (this.state.add_category) {
                    // we are adding a section category: sub-category essentially
                    // define file structure
                    const section = {
                        name: section_name,
                        shortName: section_short_name,
                        about: section_summary,
                        isDraft: radio === 'isDraft' ? true : false
                    }
    
                    // only users with admin/publisher roles are allowed to edit this property
                    if (userCanPublish) {
                        section.isPublished= radio === 'isPublished' ? true : false
                    }

                    // category to add sections to: Faqs
                    const { maincategory } = this.props;
                    // then check if null and undefined, then proceed otherwise
                    if (maincategory !== null && maincategory !== undefined && !emptySFields) {
                        // create new section category
                        this.props.createCategory(
                            maincategory._id,
                            section, user.token,
                            this.props.capitalize(this.props.link)
                        );
                        // then change state.add_category to false
                        // so that the page shows form fileds to add questions
                        this.setState({ add_category: false });
                    }
                } else if (this.state.edit_category) {
                    // we are editing a resource category: sub-category essentially
                    // define file structure
                    const edited_section = {
                        name: section_name,
                        shortName: section_short_name,
                        about: section_summary,
                        isDraft: radio === 'isDraft' ? true : false
                    }
    
                    if (userCanPublish) {
                        edited_section.isPublished= radio === 'isPublished' ? true : false
                    } else {
                        edited_section.isDraft= radio === 'isDraft' ? true : false
                    }
    
                    // category to add sections to: Faqs
                    const { maincategory } = this.props;
    
                    if (maincategory !== null && maincategory !== undefined) {
                        // make request
                        this.props.editResourceCategory(
                            maincategory._id, // resource to be edited
                            edited_section, // edited params
                            user.token, // authenticated account
                            this.props.capitalize(this.props.link)
                        );     
                        // then change state.edit_category to false
                        // so that the page shows form fields to add files and 
                        // supporting documents
                        this.setState({ 
                            edit_category: false, 
                            add_category: false,
                            edit_question: false 
                        });
                    }
                }
            }
        }
    }

    /**
     * Delete question
     */
    archiveCategory = (event) => {
        event.preventDefault();
        // question to be deleted
        const { question } = this.props;
        // if question exists then delete
        if (question?._id) {
            // then get authenticated user token
            const user = UserProfile.get();
            return this.props?.archiveCategory(
                question, 
                user?.token, 
                this.props.capitalize(this.props.link)
            );
        }
    }

    handleAlertIsOpen = (e, toggledState) => {
        e.preventDefault()
        this.setState({ isAlertOpen: toggledState })
    }

    handleAlertConfirm = e => {
        e.preventDefault()
        // handle deletion here and close dialog box
        if (this.state?.isDeleteQuestion) {
            this.archiveCategory(e)
        } else {
            this.handleDeleteSection(e)
        }
        this.handleAlertIsOpen(e, !this.state?.isAlertOpen)
    }

    editSection = () => {
        const { classes, userCanPublish } = this.props;
        const { 
            section, 
            resource_name, 
            resource_short_name, 
            resource_summary, 
            radio 
        } = this.state;

        return (
            <Fragment>
                <div className='margin-fix form-row'>
                    <BootstrapGridColumn>
                        <BootsrapTextField
                            value={section ? 
                                (resource_name ? resource_name : section.name) 
                                : null
                            }
                            name='resource_name'
                            label="Section*"
                            placeholder="Edit section name..."
                            type="text"
                            handleChange={this.handleTextChange}
                        />
                    </BootstrapGridColumn>
                    <BootstrapGridColumn>
                        <BootsrapTextField
                            name="resource_short_name"
                            placeholder="Edit section shortname..."
                            type="text"
                            label="Shortname*"
                            value={section ? 
                                (resource_short_name ? resource_short_name : section.shortName) 
                                : null
                            }
                            handleChange={this.handleTextChange}
                        />
                    </BootstrapGridColumn>
                </div>

                <div className="form-group">
                    <BootsrapTextareaField
                        value={section ? 
                            (resource_summary ? resource_summary : section.about) 
                            : null
                        }
                        name='resource_summary'
                        label="Summary*"
                        placeholder="Edit section summary..."
                        type="text"
                        rows={5}
                        handleChange={this.handleTextChange}
                    />
                </div>

                <div className={classes.margin} />
                <div className={classes.margin} style={{ marginBottom: '1.5em' }} />

                <RadioGroup
                    label="Publication Status*"
                    onChange={this.handleRadioChange}
                    selectedValue={radio}
                >
                    <Radio 
                        label='Draft' 
                        value="isDraft" 
                    />
                    <Radio 
                        label='Published' 
                        value="isPublished" 
                        disabled={userCanPublish ? false : true}
                    />
                </RadioGroup>

                <Button icon='updated'
                    type="submit" 
                    disabled={
                        !(resource_name || resource_short_name || resource_summary || radio)
                    }
                    intent="success" 
                    text={`Save changes`} 
                />

                <Can I='delete' a='Category'>
                    <Button 
                        icon='delete'
                        className={classes.margin} 
                        intent="danger" 
                        text="Delete category"  
                        onClick={e => this.handleAlertIsOpen(e, 
                            !this.state.isAlertOpen)
                        }
                    />
                </Can>

                <Button icon='arrow-left'
                    className={classes.margin} 
                    name="default" 
                    intent="primary" 
                    text="Cancel" 
                    onClick={() => {
                        if (this.state.edit_category) {
                            this.setState({ 
                                edit_category: false, 
                                add_category: false, 
                                edit_question: true 
                            })
                        }
                    }} 
                />
            </Fragment>
        );
    }

    render() {

        const { classes, handleClick, general, question, userCanPublish } = this.props;

        if (question !== null && this.state._question == null) {
            Object.assign(this.state, { question });
        }
        // console.log(this.state)

        // Frequently asked question sections
        const sections = this.props.maincategory;

        const { 
            _question, editorText, shortName, radio,
            section_name, section_short_name, section_summary 
        } = this.state;

        const emptyQFields = _question || editorText || shortName || radio ? false : true;
        const emptySFields = section_name && section_short_name && section_summary && radio ? false : true;

        return (
            <Fragment>
                <h5 className='heading-text'>
                    Edit a question or manage categories for different questions
                </h5>

                <ConfirmActionManager 
                    alertMessage={() => {
                        return this.state.isDeleteQuestion ? <Fragment>
                            Are you sure you want to delete <b>{
                                `${ this.state?.question?.name }`
                            }</b>? You will not be able to restore this question later.
                        </Fragment> : <Fragment>
                            Are you sure you want to delete <b>{
                                `${ this.state?.section?.name }`
                            }</b>? You will not be able to restore this category of questions later.
                        </Fragment>
                    }}
                    cancelButtonText="Cancel deletion"
                    confirmButtonText="Confirm deletion"
                    isAlertOpen={this.state?.isAlertOpen} 
                    handleAlertCancel={e => this.handleAlertIsOpen(e, !this.state.isAlertOpen)}
                    handleAlertConfirm={this.handleAlertConfirm}
                />

                <form onSubmit={(e) => this.handleSubmit(e)} autoComplete="off">
                    {
                        this.state?.edit_question ? (
                            <Fragment>
                                { /** filter sections here */}
                                <FormControl>
                                    <Paper elevation={0}>
                                        <SelectInputControl
                                            name="section"
                                            label='Choose category'
                                            {...this.state}
                                            onChange={e => this.handleChange(e)}
                                        >
                                            <option value="">{`---Category---`}</option>
                                            {
                                                sections?.subCategories?.map(({ 
                                                    _id, name 
                                                }, index) => {
                                                    // section
                                                    return <option id={_id} 
                                                        key={`${index}`} 
                                                        value={name}
                                                    >
                                                        {name}
                                                    </option>
                                                })
                                            }
                                        </SelectInputControl>
                                    </Paper>
                                </FormControl>

                                { /** New frequently asked questions section */}
                                <Button
                                    icon='new-object'
                                    className={classes.margin}
                                    name="add_category"
                                    value={this.state?.add_category}
                                    intent="primary" 
                                    text="Create category"
                                    onClick={e => this.handleAddSection(e)}
                                />

                                {
                                    this.state?.section && (
                                        // show these buttons only when
                                        // an item is selected.
                                        !this.state?.add_category && (
                                            <>
                                            <Can I='update' a='Category'>
                                                <Button
                                                    icon='edit'
                                                    className={classes.margin}
                                                    name="edit_category"
                                                    value={this.state.edit_category}
                                                    intent="primary" 
                                                    text="Edit selected"
                                                    onClick={e => this.handleEditSection(e)}
                                                />
                                            </Can>
                                            <Can I='delete' a='Category'>
                                                <Button
                                                    icon='delete'
                                                    className={classes.margin}
                                                    name="delete_section"
                                                    id={this.state.section._id}
                                                    value={this.state.section._id}
                                                    intent="danger" 
                                                    text="Delete selected"
                                                    onClick={e => {
                                                        this.setState({ isDeleteQuestion: false })
                                                        return this.handleAlertIsOpen(e, 
                                                            !this.state.isAlertOpen)
                                                    }}
                                                />
                                            </Can>
                                        </>)
                                    )
                                }

                                <div className={classes.margin} />

                                <Fragment>
                                    <div className='margin-fix form-row'>
                                        <BootstrapGridColumn>
                                            <BootsrapTextField
                                                name="_question"
                                                value={this.state?.question ? (this.state._question ? this.state._question : this.state.question.name) : null}
                                                label="Question"
                                                type="text"
                                                placeholder="Edit question name..."
                                                handleChange={this.handleTextChange}
                                            />
                                        </BootstrapGridColumn>
                                        <BootstrapGridColumn>
                                            <BootsrapTextField
                                                name="shortName"
                                                type="text"
                                                placeholder="Edit question shortname..."
                                                label="Shortname"
                                                value={this.state?.question ? (this.state?.shortName ? this.state?.shortName : this.state?.question.shortName) : null}
                                                handleChange={this.handleTextChange}
                                            />
                                        </BootstrapGridColumn>
                                    </div>

                                    <CustomCKEditor
                                        editorText={this.state?.question ? (this.state?.editorText ? this.state?.editorText : this.state?.question.about) : null}
                                        label="Answer"
                                        setEditorText={this.setEditorText}
                                    />

                                    {
                                        general?.isLoading ? (<div className="loader" />) : null
                                    }

                                </Fragment>

                                <div className={classes.margin} />

                                <RadioGroup
                                    label="Publication Status*"
                                    onChange={e=>this.handleRadioChange(e)}
                                    selectedValue={this.state?.radio}
                                >
                                    <Radio 
                                        label='Draft' 
                                        value="isDraft" 
                                    />
                                    <Radio 
                                        label='Published' 
                                        value="isPublished" 
                                        disabled={userCanPublish ? false : true}
                                    />
                                </RadioGroup>

                                <Button
                                    icon='new-object'
                                    className={classes.margin}
                                    type="submit" 
                                    disabled={emptyQFields}
                                    intent="success" 
                                    text="Save changes"
                                />

                                <Can I='delete' a='Category'>
                                    <Button
                                        icon='delete'
                                        id={this.state?.question?._id}
                                        key={this.state?.question?._id}
                                        className={classes.margin}
                                        name="archive"
                                        disabled={general?.isLoading}
                                        intent="danger" 
                                        text="Delete question"
                                        onClick={e => {
                                            this.setState({ isDeleteQuestion: true })
                                            return this.handleAlertIsOpen(e, 
                                                !this.state.isAlertOpen)
                                        }}
                                    />
                                </Can>

                                <Button
                                    icon='arrow-left'
                                    className={classes.margin}
                                    name="default"
                                    intent="primary" 
                                    text="Cancel"
                                    onClick={e => handleClick(e)}
                                />
                            </Fragment>
                        ) : (
                                <Fragment>
                                    { this.state?.add_category ? (
                                        <Fragment>
                                        <div className='margin-fix form-row'>
                                            <BootstrapGridColumn>
                                                <BootsrapTextField
                                                    name="section_name"
                                                    label="Section*"
                                                    type="text"
                                                    placeholder="Enter section name..."
                                                    handleChange={this.handleTextChange}
                                                />
                                            </BootstrapGridColumn>
                                            <BootstrapGridColumn>
                                                <BootsrapTextField
                                                    name="section_short_name"
                                                    type="text"
                                                    placeholder="Enter section shortname..."
                                                    label="Shortname*"
                                                    handleChange={this.handleTextChange}
                                                />
                                            </BootstrapGridColumn>
                                        </div>

                                        <div className="form-group">
                                            <BootsrapTextareaField
                                                name="section_summary"
                                                placeholder="Enter section summary..."
                                                label="Summary*"
                                                type="text"
                                                rows={5}
                                                handleChange={this.handleTextChange}
                                            />
                                        </div>

                                        <div className={classes.margin} />

                                        <RadioGroup
                                            label="Publication Status*"
                                            onChange={e=>this.handleRadioChange(e)}
                                            selectedValue={this.state?.radio}
                                        >
                                            <Radio 
                                                label='Draft' 
                                                value="isDraft" 
                                            />
                                            <Radio 
                                                label='Published' 
                                                value="isPublished" 
                                                disabled={userCanPublish ? false : true}
                                            />
                                        </RadioGroup>

                                        <Button
                                            icon='new-object'
                                            type="submit" 
                                            disabled={emptySFields}
                                            intent="success" 
                                            text="Save section"
                                        />
                                        <Button
                                            icon='arrow-left'
                                            className={classes.margin}
                                            intent="primary" 
                                            text="Cancel"
                                            onClick={() => {
                                                if (this.state?.add_category) {
                                                    this.setState({ 
                                                        edit_category: false, 
                                                        add_category: false, 
                                                        edit_question: true 
                                                    })
                                                }
                                            }}
                                        />
                                    </Fragment>
                                    ) : this.editSection() }
                                </Fragment>
                            )
                    }
                </form>
            </Fragment>
        );
    }
}

EditQuestion.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default (withStyles(styles)(EditQuestion));