import React, { useState } from "react";
import {
  Button,
  Card,
  Callout,
  Collapse,
  Elevation,
  Icon,
} from "@blueprintjs/core";
import { redirect } from "../../user/user.redirect";
import Config from "../../../config";

const button = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  borderRadius: "0",
  background: "#BFCCD6",
  fontSize: "1.2em",
  padding: "10px",
  cursor: "pointer",
};

const docContainer = {
  marginBottom: "10px",
  textAlign: "left",
};

const alignCenter = {
  textAlign: "center",
};

export default function Document({ id, name, path, summary, index, size }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div style={docContainer}>
      <Callout
        onClick={() => setIsOpen(!isOpen)}
        style={button}
        righticon="add"
      >
        <div style={{ alignSelf: "flex-start" }}>{`${index}. ${name}`}</div>
        <div style={{ marginLeft: "auto" }}>
          {isOpen ? <Icon icon="remove" /> : <Icon icon="add" />}
        </div>
      </Callout>
      <Collapse isOpen={isOpen}>
        <Card interactive={true} elevation={Elevation.ZERO}>
          <p> {summary} </p>
          <a
            href={`${Config.CTECH_REMOTE_PROD_SERVER}files/download/${id}`}
            onClick={(e) => redirect.toExternalLink({ url: path, event: e })}
            download={name}
          >
            <Button
              style={alignCenter}
              rightIcon="download"
              intent="success"
              text={`Download - ${Math.round(size / 1000000)}MB`}
            />
          </a>
        </Card>
      </Collapse>
    </div>
  );
}
