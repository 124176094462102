import React from 'react'
import { AbilityContext, defineAbilityFor, useAuth } from "./user.permissions"

export default function AccessProvider(props) {
  const { user } = useAuth();

  return (
    <AbilityContext.Provider value={defineAbilityFor(user)}>
      {props.children}
    </AbilityContext.Provider>
  );
}
