import React, { createContext, useContext, useState } from "react";
import { AbilityBuilder, createMongoAbility } from "@casl/ability";
import { createContextualCan } from "@casl/react";
import userProfile from "../user.profile";

export const defineAbilityFor = (user) => {
    const { rules } = new AbilityBuilder(createMongoAbility)
    let abilities = JSON.parse(user?.abilities)
    return createMongoAbility(rules).update(abilities)
}

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

const AuthContext = createContext();
export const AuthProvider = (props) => {
    const authUser = userProfile.get()
    const [user, setUser] = useState({ ...authUser });
    return <AuthContext.Provider {...props} value={{ user, setUser }} />;
};

export const useAuth = () => useContext(AuthContext)