import React, { Fragment, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { Row } from "reactstrap";
import { Button, Tooltip, withStyles } from "@material-ui/core";
import styles from "../contact/form.styles";
import { Can, useAuth } from "../user/access/user.permissions";
import { ToolbarManager } from "../cms/common/toolbar.manager";
import { algorithms } from "../common/algorithms";

const VALUES = ["all"];

/**
 * Lists all institutions in the directory
 * or registered with the Department of Energy through the system
 *
 * @author Isaac S. Mwakabira
 *
 * @param {Object} directory
 * @param {Function} handleClick
 * @param {Function} handleChange
 *
 * @returns {Fragment} directory
 */
export const ListDirectoryInstitution = withStyles(styles)(
  ({ stakeholders, handleClick, general, classes }) => {
    const [filters, setFilters] = useState(VALUES);

    // authenticated user
    const auth = useAuth();

    return (
      <Fragment>
        <ToolbarManager
          filters={filters}
          setFilters={setFilters}
          handleClick={handleClick}
          classes={classes}
        >
          <Can I="create" a="Stakeholder">
            <Fragment>
              <Tooltip title="Create new stakeholder">
                <Button name="create" onClick={(e) => handleClick(e)}>
                  <AddIcon /> New stakeholder
                </Button>
              </Tooltip>
            </Fragment>
          </Can>
        </ToolbarManager>

        <ul>
          {!general?.isLoading ? (
            stakeholders
              ?.filter((object) => {
                // Show only those that are published
                // hide if the authenticated account is not the one which created the object
                const published = object?.isPublished;
                const authRoles = Object.keys(auth?.user.roles)[0];
                // draft and not author and is writer
                if (
                  !published &&
                  auth?.user._id !== object.createdBy &&
                  authRoles === "writer"
                ) {
                  return false;
                }
                return true;
              })
              ?.filter((stakeholder) =>
                algorithms.filterByProperty(filters, stakeholder)
              )
              ?.map((stakeholder, index) => {
                return (
                  <Fragment key={index}>
                    <li id={stakeholder._id} key={stakeholder._id}>
                      <Can I="update" a="Stakeholder" passThrough>
                        {(allowed) =>
                          !allowed ? (
                            <a href="#/">{stakeholder.name}</a>
                          ) : (
                            <a
                              href={`${"directory/" + stakeholder.name}`}
                              onClick={(e) => handleClick(e)}
                              name="edit"
                              id={stakeholder._id}
                            >
                              {stakeholder.name}
                            </a>
                          )
                        }
                      </Can>
                    </li>
                  </Fragment>
                );
              })
          ) : (
            <Row>
              <div style={{ marginTop: `40px` }} className="loader" />
            </Row>
          )}
        </ul>
      </Fragment>
    );
  }
);
