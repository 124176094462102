
export const LibraryType = {
    FETCH_LIBRARY: "FETCH_LIBRARY",
    FETCH_LIBRARY_DOCS: "FETCH_LIBRARY_DOCS",
    REQUEST_SUB_CATE_DOCS: "REQUEST_SUB_CATE_DOCS",
    CREATE_NEW_SUB_CATEGORY: "CREATE_NEW_SUB_CATEGORY",
    ADD_NEW_SUB_CATEGORY_DOCS: "ADD_NEW_SUB_CATEGORY_DOCS",
    ARCHIVE_SUB_CATEGORY_DOC: "ARCHIVE_SUB_CATEGORY_DOC",
    DELETE_SUB_CATEGORY_DOC: "DELETE_SUB_CATEGORY_DOC",
    SAVE_SUB_CATEGORY_DOC: "SAVE_SUB_CATEGORY_DOC",
    UPLOAD_FILE: "UPLOAD_FILE",
    FETCH_LIBRARY_FILE: "FETCH_LIBRARY_FILE",
    UPDATE_LIBRARY_FILE: "UPDATE_LIBRARY_FILE"
}
