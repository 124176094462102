import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import styles from '../contact/form.styles';
import UserProfile from '../user/user.profile';
import { Button, RadioGroup, Radio } from '@blueprintjs/core';
import BootstrapGridColumn from '../forms/form.grid.column';
import { BootsrapTextField } from '../forms/form.bootstrap.field';
import CustomCKEditor from '../ckeditor/editor.component';

/**
 * Create new licensing step
 * 
 * @author Isaac S. Mwakabira
 */
class CreateLicensingStep extends Component {

    constructor() {
        super();
        this.state = {}

        /**
         * Bind events to each Function, so that they can be passed without args 
         * i.e this.handleChange
         * 
         */
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleRadioChange = (e) => {
        this.setState({ radio: e.target.value })
    }

    componentDidMount() {
        this.setState({
            radio: this.props?.subcategory?.isPublished ? 'isPublished' : 'isDraft'
        })
    }

	/**
	 * On change, update the app's React state with event type value.
	 *
	 * @param {Event} event
	 */
    handleChange = (event) => {

        this.setState({ [event.target.name]: event.target.value });

    }

    setEditorText = (editor) => {
        this.setState({ editorText: editor.getData() });
    }

    handleSubmit = (event) => {
        // prevent default behaviour
        event.preventDefault();
        // get category
        const { maincategory, userCanPublish } = this.props;
        const { name, shortname, editorText, radio } = this.state;
        // authenticated user token
        const user = UserProfile.get();
        if (user?.token) {
            if (name && shortname && editorText && radio) {
                // define sub-category structure
                const sub_category = {
                    name: name,
                    shortName: shortname,
                    about: editorText,
                    isDraft: radio === 'isDraft' ? true : false
                }

                // only users with admin/publisher roles are allowed to edit this property
                if (userCanPublish) {
                    sub_category.isPublished= radio === 'isPublished' ? true : false
                }

                this.props.createCategory(
                    maincategory?._id, 
                    sub_category, 
                    user.token, 
                    this.props.capitalize(this.props.link)
                );
                // then change state to default
                // so that the page redirects and list all licensing items
                this.props.defaultItem();
            }
        }
    }

    render() {
        // props
        const { classes, handleClick, userCanPublish } = this.props;
        const { name, shortname, editorText, radio } = this.state;

        const roles = Object.keys(UserProfile.get().roles)[0]

        return (
            <Fragment>
                <h5 className='heading-text'>Create licensing step</h5>

                <form onSubmit={(e) => this.handleSubmit(e)} autoComplete="off">
                    <div className='margin-fix form-row'>
                        <BootstrapGridColumn>
                            <BootsrapTextField
                                value={this.state.name}
                                name="name"
                                label="Name*"
                                type="text"
                                placeholder="Enter license process step..."
                                handleChange={this.handleChange}
                            />
                        </BootstrapGridColumn>
                        <BootstrapGridColumn>
                            <BootsrapTextField
                                name="shortname"
                                type="text"
                                placeholder="Enter license process step shortname..."
                                label="Shortname*"
                                value={this.state.shortname}
                                handleChange={this.handleChange}
                            />
                        </BootstrapGridColumn>
                    </div>
                    
                    <CustomCKEditor
                        {...this.state}
                        label="Summary Text*"
                        setEditorText={this.setEditorText}
                    />
                    <div className={classes.margin} />

                    <RadioGroup
                        label="Publication Status*"
                        onChange={e=>this.handleRadioChange(e)}
                        selectedValue={radio}
                    >
                        <Radio 
                            label='Draft' 
                            value="isDraft" 
                        />
                        <Radio 
                            label='Published' 
                            value="isPublished" 
                            disabled={roles !== 'writer' ? false : true}
                        />
                    </RadioGroup>

                    <Button
                        icon='new-object'
                        type="submit"
                        disabled={!(name && shortname && editorText && radio)}
                        intent="success"
                        text="Save step"
                    />

                    <Button
                        icon='arrow-left'
                        className={classes.margin}
                        name="default"
                        intent="primary"
                        text="Cancel"
                        onClick={e => handleClick(e)}
                    />
                </form>
            </Fragment>
        );
    }
}

CreateLicensingStep.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default (withStyles(styles)(CreateLicensingStep));