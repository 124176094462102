import React, { useEffect, useState } from 'react';
import { Col, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Link } from "react-router-dom";

// Filter a section from home
export const filterSection = (sections, name) => {
    if (!(sections?.length > 0)) {
      return null;
    }

    return sections?.filter(section => {
      if (section?.name === name) {
        return section;

      } 
      return null;
    })[0];
}

export const HomeSubCategory = ({
    section,
    subCategories
}) => {
    const [homeSection, setHomeSection] = useState()
    const [modal, setModal] = useState(false)

    useEffect(() => {
        if (subCategories.length > 0 && section) {
            setHomeSection(filterSection(subCategories, section))
        }
    }, [section, subCategories])
    
    const renderReadMore = (about) => {
        if (about?.length > 250){
            return (<span 
                onClick={toggle} 
                className="badge badge-info" 
                style={{cursor: 'pointer'}}
            >
                Read more...
            </span>)
        }
        else{
            return ''
        }
    }

    const toggle = () => setModal(!modal)

    const link = (props) => {
        let url;
        if (props) {
            let section_name = (props?.name)?.toLowerCase();
            if (props?.name !== "Information for Mini-Grid Developers") {
                url = section_name;
                section_name = '';
            } else {
                url = section_name;
                section_name = '';
            }
            return url;
        }
    }

    const linkButton = (props) => {
        if(props?.name) {
            return (
                <Link to={ `${ '/' +  link(props) }`}>
                    <button className="btn btn-primary">{ props?.name } section </button>
                </Link>
            )
        }
        return null
    }

    return (
        <Col sm='12' md='6' lg='4'>
            <div className="card card-item" style={{ minHeight: 'auto' }}>
                <div className="card-body">
                    <h4>
                        <a className="heading" href={ link(homeSection) }>
                            { homeSection?.name }
                        </a>
                    </h4>
                    <span style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{
                        __html: `${homeSection?.about?.substring(0, 250)}`
                    }}/>
                    
                    { renderReadMore(homeSection?.about) }
                </div>
                <div 
                    className="card-footer" 
                    style={{  backgroundColor: 'unset', borderTop: 'unset' }}
                >
                    {
                        homeSection?.name !== "Information for Mini-Grid Developers" 
                            ? linkButton(homeSection) : null
                    }
                </div>
            </div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>{homeSection?.name}</ModalHeader>
                <ModalBody>
                    {homeSection?.about}
                    <br/>
                    <p>
                        {
                            homeSection?.name !== "Information for Mini-Grid Developers" 
                                ? linkButton(homeSection) : <></>
                        }
                    </p>
                </ModalBody>
            </Modal>
        </Col>
    )
}