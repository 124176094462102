import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, RadioGroup, Radio } from '@blueprintjs/core';
import styles from '../contact/form.styles';
import { BootsrapTextField } from '../forms/form.bootstrap.field';
import CustomCKEditor from '../ckeditor/editor.component';
import { Can, useAuth } from '../user/access/user.permissions';
import ConfirmActionManager from '../common/alerts/confirmation.alert';

/**
 * Edit a single news article
 * 
 * @author Isaac S. Mwakabira
 * 
 */
const EditNewsItem = ({
    classes, 
    handleClick, 
    general,
    article,
    editArticle,
    deleteArticle,
    userCanPublish
}) => {
    const [isAlertOpen, setIsAlertOpen] = useState(false)
    const [editorText, setEditorText] = useState()
    const [title, setTitle] = useState()
    const [news, setNewsArticle] = useState()
    const [radio, setRadio] = useState(null)

    const auth = useAuth()

    const handleRadioChange = (e) => {
        setRadio(e.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (auth?.user?.token && article?._id) {
            // define article object
            const newsArticle = {
                title: title,
                article: editorText,
                isDraft: radio === 'isDraft' ? true : false
            }

            // only users with admin/publisher roles are allowed to edit this property
            if (userCanPublish) {
                newsArticle.isPublished= radio === 'isPublished' ? true : false
            }

            // then make post request to the api
            editArticle(article?._id, newsArticle, auth?.user?.token);
        }
    }

    /**
     * Delete category
     */
    const handleArchiveCategory = (event) => {
        event.preventDefault();
        // if article exists then delete
        if (auth?.user?.token && article?._id) {
            deleteArticle(article._id, auth?.user.token);
        }
    }

    const handleAlertIsOpen = (e, toggledState) => {
        e.preventDefault()
        setIsAlertOpen(toggledState)
    }

    const handleAlertConfirm = e => {
        e.preventDefault()
        // handle deletion of section and close dialog box
        handleArchiveCategory(e)
        handleAlertIsOpen(e, !isAlertOpen)
    }

    useEffect(() => {
        if (article) {
            setNewsArticle(article?.article)
            setRadio(article?.isPublished ? 'isPublished' : 'isDraft')
        }
    }, [article])

    return (
        <Fragment>
            <h5 className='heading-text'>Edit news article</h5>

            <ConfirmActionManager 
                alertMessage={() => {
                    return <Fragment>
                        Are you sure you want to delete <b>{
                            `${ article?.title }`
                        }</b>? You will not be able to restore this news item later.
                    </Fragment>
                }}
                cancelButtonText="Cancel deletion"
                confirmButtonText="Confirm deletion"
                isAlertOpen={isAlertOpen} 
                handleAlertCancel={e => handleAlertIsOpen(e, !isAlertOpen)}
                handleAlertConfirm={handleAlertConfirm}
            />

            <form onSubmit={(e) => handleSubmit(e)} autoComplete="off">
                {
                    !general?.isLoading ? (
                        <Fragment>
                            <div className={classes.margin}>
                                <div className="margin-fix form-row">
                                    <BootsrapTextField
                                        name="title"
                                        value={article ? (title ? title : article?.title) : ''}
                                        placeholder="Edit article title..."
                                        label="Article Title"
                                        type="text"
                                        handleChange={e => setTitle(e.target.value)}
                                    />
                                </div>

                                <CustomCKEditor
                                    editorText={editorText ? editorText : news}
                                    label="Article Text"
                                    setEditorText={editor => setEditorText(editor?.getData())}
                                />
                            </div>

                            <RadioGroup
                                label="Publication Status*"
                                onChange={handleRadioChange}
                                selectedValue={radio}
                            >
                                <Radio 
                                    label='Draft' 
                                    value="isDraft" 
                                />
                                <Radio 
                                    label='Published' 
                                    value="isPublished" 
                                    disabled={userCanPublish ? false : true}
                                />
                            </RadioGroup>

                            <Button
                                icon='new-object'
                                className={classes.margin}
                                type="submit" 
                                disabled={title || editorText ? false : true}
                                intent="success" 
                                text="Save changes"
                            />

                            <Can I='delete' a='News'>
                                <Button
                                    icon='delete'
                                    id={article?._id}
                                    className={classes.margin}
                                    name="archive"
                                    disabled={general?.isLoading}
                                    intent="danger" 
                                    text="Delete news item"
                                    onClick={e => handleAlertIsOpen(e, !isAlertOpen)}
                                />
                            </Can>

                            <Button
                                icon='arrow-left'
                                className={classes.margin}
                                name="default"
                                intent="primary" 
                                text="Cancel"
                                onClick={e => handleClick(e)}
                            />
                        </Fragment>
                    ) : <div className="loader" />
                }
                <div className={classes.margin} />
            </form>
        </Fragment>
    );
}

EditNewsItem.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default (withStyles(styles)(EditNewsItem));