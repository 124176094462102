import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Radio, RadioGroup } from '@blueprintjs/core';
import styles from '../contact/form.styles';
import * as NewsAction from '../../actions/news.action';
import { BootsrapTextField } from '../forms/form.bootstrap.field';
import CustomCKEditor from '../ckeditor/editor.component';
import { useAuth } from '../user/access/user.permissions';

const CreateNewsItem = ({
    classes, 
    handleClick, 
    createArticle,
    defaultItem,
    userCanPublish
}) => {
    const [editorText, setEditorText] = useState()
    const [title, setTitle] = useState()
    const [radio, setRadio] = useState('isDraft')

    const auth = useAuth()
    const roles = Object.keys(auth?.user.roles)[0]

    const handleRadioChange = (e) => {
        setRadio(e.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (auth?.user?.token) {
            // define article object
            const article = {
                title: title,
                article: editorText,
                isDraft: true
            }

            // only users with admin/publisher roles are allowed to edit this property
            if (userCanPublish) {
                article.isPublished= radio === 'isPublished' ? true : false
            } else {
                article.isDraft= radio === 'isDraft' ? true : false
            }

            if (article.article) {
                // then make post request to the api
                createArticle(article, auth?.user?.token);
                // then change state to default
                // so that the page redirects and list all home items
                defaultItem();
            }
        }
    }

    return (
        <Fragment>
            <h5 className='heading-text'>Create an article</h5>

            <form onSubmit={(e) => handleSubmit(e)} autoComplete="off">
                <div className="margin-fix form-row">
                    <BootsrapTextField
                        name="title"
                        value={title}
                        placeholder="Enter article title..."
                        label="Article Title"
                        type="text"
                        handleChange={e => setTitle(e.target.value)}
                    />
                </div>
                <CustomCKEditor
                    editorText={editorText}
                    label="Article Text"
                    setEditorText={editor => setEditorText(editor.getData())}
                />

                <div className={classes.margin} />

                <RadioGroup
                    label="Publication Status*"
                    onChange={handleRadioChange}
                    selectedValue={radio}
                >
                    <Radio 
                        label='Draft' 
                        value="isDraft" 
                    />
                    <Radio 
                        label='Published' 
                        value="isPublished" 
                        disabled={roles !== 'writer' ? false : true}
                    />
                </RadioGroup>

                <Button
                    icon='new-object'
                    className={classes.margin}
                    type="submit" 
                    disabled={!(title && editorText)}
                    intent="success" 
                    text="Save news item"
                />

                <Button
                    icon='arrow-left'
                    className={classes.margin}
                    name="default"
                    intent="primary" 
                    text="Cancel"
                    onClick={e => handleClick(e)}
                />
            </form>
        </Fragment>
    );
}

CreateNewsItem.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        errored: state.news.errored,
        general: state.general.general,
        article: state.news.article,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createArticle: (article, user) => dispatch(NewsAction.createArticle(article, user)),
    }
}

export default (withStyles(styles)(connect(
        mapStateToProps,
        mapDispatchToProps
    )(CreateNewsItem)))