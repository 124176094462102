import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Button, Radio, RadioGroup } from '@blueprintjs/core';
import styles from '../contact/form.styles';
import '../cms/style.css';
import UserProfile from '../user/user.profile';
import BootstrapGridColumn from '../forms/form.grid.column';
import { BootsrapTextField } from '../forms/form.bootstrap.field';
import { BootsrapTextareaField } from '../forms/form.textarea.field';
import { Can } from '../user/access/user.permissions';
import ConfirmActionManager from '../common/alerts/confirmation.alert';

/**
 * Edit a home subcategory
 * 
 * @author Isaac S. Mwakabira
 * 
 */
const EditHomeSubcategory = ({
    subcategory,
    archiveCategory,
    editCategory,
    capitalize,
    classes, 
    handleClick, 
    general,
    link,
    userCanPublish
}) => {
    const [isAlertOpen, setIsAlertOpen] = useState(false)
    const [name, setName] = useState()
    const [shortname, setShortname] = useState()
    const [about, setAbout] = useState()
    const [radio, setRadio] = useState(null)

    const handleRadioChange = (e) => {
        setRadio(e.target.value)
    }

    const handleSubmit = (event) => {
        // Prevent default submit action
        event.preventDefault();
        // get authenticated user token
        const user = UserProfile.get();
        if (user?.token) {
            let edited_sub_category;
            if (name || shortname|| about || radio) {
                // get sub-category structure
                edited_sub_category = {
                    name: capitalize(name),
                    shortName: shortname,
                    about: about,
                    isDraft: radio === 'isDraft' ? true : false
                }

                // only users with admin/publisher roles are allowed to edit this property
                if (userCanPublish) {
                    edited_sub_category.isPublished= radio === 'isPublished' ? true : false
                }

                // then edit this sub category
                editCategory(
                    subcategory._id, 
                    edited_sub_category, 
                    user.token, 
                    subcategory, 
                    capitalize(link)
                );
            }
        }
    }

    /**
     * Delete category
     */
    const handleArchiveCategory = (event) => {
        event.preventDefault();
        // if subcategory exists then delete
        if (subcategory?._id) {
            // then get authenticated user token
            const user = UserProfile.get();
            if (user?.token) {
                return archiveCategory(
                    subcategory, 
                    user.token, 
                    capitalize(link)
                );
            }
        }
    }

    const handleAlertIsOpen = (e, toggledState) => {
        e.preventDefault()
        setIsAlertOpen(toggledState)
    }

    const handleAlertConfirm = e => {
        e.preventDefault()
        // handle deletion of section and close dialog box
        handleArchiveCategory(e)
        handleAlertIsOpen(e, !isAlertOpen)
    }

    useEffect(() => {
        setRadio(subcategory?.isPublished ? 'isPublished' : 'isDraft')
    }, [subcategory])

    return (
        <Fragment>
            <h5 className='heading-text'>Edit section</h5>

            <div className={classes.margin} />

            { general?.isLoading ? (<div className="loader" />) : null }
            
            <ConfirmActionManager 
                alertMessage={() => {
                    return <Fragment>
                        Are you sure you want to delete <b>{
                            `${ subcategory?.name }`
                        }</b>? You will not be able to restore this section later.
                    </Fragment>
                }}
                cancelButtonText="Cancel deletion"
                confirmButtonText="Confirm deletion"
                isAlertOpen={isAlertOpen} 
                handleAlertCancel={e => handleAlertIsOpen(e, !isAlertOpen)}
                handleAlertConfirm={handleAlertConfirm}
            />

            <form onSubmit={(e) => handleSubmit(e)} autoComplete="off">
                {
                    subcategory !== null && (
                        <div>

                            <div className="margin-fix form-row">
                                <BootstrapGridColumn>
                                    <BootsrapTextField
                                        value={
                                            subcategory !== null 
                                            ? (name ? name : subcategory.name) : ''
                                        }
                                        name='name'
                                        label='Name'
                                        type='text'
                                        // id={subcategory._id}
                                        placeholder="Edit section's name..."
                                        handleChange={e=>setName(e.target.value)}
                                    />
                                </BootstrapGridColumn>
                                <BootstrapGridColumn>
                                    <BootsrapTextField
                                        value={
                                            subcategory !== null 
                                            ? (shortname ? shortname : subcategory.shortName) : ''
                                        }
                                        name='shortname'
                                        label='Shortname'
                                        type='text'
                                        // id={subcategory._id}
                                        placeholder="Edit section's shortname..."
                                        handleChange={e=>setShortname(e.target.value)}
                                    />
                                </BootstrapGridColumn>
                            </div>

                            <div className="form-group">
                                <BootsrapTextareaField
                                    name="about"
                                    value={
                                        subcategory !== null
                                            ? (about ? about : subcategory.about) : ''
                                    }
                                    placeholder="Edit section's about..."
                                    label="About"
                                    type="text"
                                    rows={10}
                                    handleChange={e=>setAbout(e.target.value)}
                                />
                            </div>
                        </div>
                    )
                }

                <div className={classes.margin} />
                <div className={classes.margin} style={{ marginBottom: '1.5em' }}/>

                <RadioGroup
                    label="Publication Status*"
                    onChange={handleRadioChange}
                    selectedValue={radio}
                >
                    <Radio 
                        label='Draft' 
                        value="isDraft" 
                    />
                    <Radio 
                        label='Published' 
                        value="isPublished" 
                        disabled={userCanPublish ? false : true}
                    />
                </RadioGroup>

                <Button icon='new-object'
                    type="submit" 
                    disabled={!(name || shortname || about || radio)}
                    intent="success" 
                    text="Save changes" 
                />

                <Can I='delete' a='Category'>
                    <Button 
                        icon='delete'
                        className={classes.margin} 
                        intent="danger" 
                        text="Delete section" 
                        disabled={general?.isLoading}
                        onClick={(e) => handleAlertIsOpen(e, !isAlertOpen)} 
                    />
                </Can>

                <Button icon='arrow-left'
                    className={classes.margin} 
                    name="default" 
                    intent="primary" 
                    text="Cancel" 
                    onClick={e => handleClick(e)} 
                />
            </form>
        </Fragment>
    );
}

EditHomeSubcategory.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default (withStyles(styles)(EditHomeSubcategory));