import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/Add";
import { Button, Tooltip, withStyles } from "@material-ui/core";
import styles from "../contact/form.styles";
import { ToolbarManager } from "../cms/common/toolbar.manager";
import { Can, useAuth } from "../user/access/user.permissions";
import { algorithms } from "../common/algorithms";

const VALUES = ["all"];

/**
 * Component to list all requests so far placed by each
 * individual institution.
 *
 * @author Isaac S. Mwakabira
 *
 */
export const ListFinancingRequests = withStyles(styles)(
  ({ maincategory, handleClick, general, classes }) => {
    const [filters, setFilters] = useState(VALUES);
    // authenticated account
    const auth = useAuth();

    console.log(maincategory)

    return (
      <Fragment>
        <ToolbarManager
          filters={filters}
          setFilters={setFilters}
          handleClick={handleClick}
          classes={classes}
        >
          <Can I="create" a="Category">
            <Fragment>
              <Tooltip title="Create new financing step">
                <Button name="create" onClick={(e) => handleClick(e)}>
                  <AddIcon /> New financing step
                </Button>
              </Tooltip>
            </Fragment>
          </Can>
        </ToolbarManager>

        <ul>
          {!general?.isLoading ? (
            maincategory?.subCategories?.length !== 0 ? (
              maincategory?.subCategories
                ?.filter((object) =>
                  algorithms.filterByUserAbility(object, auth)
                )
                ?.filter((item) => algorithms.filterByProperty(filters, item))
                ?.map((category, index) => {
                  return (
                    <li key={index}>
                      <Can I="update" a="Category">
                        <a
                          href={`${"/steps/" + category.name}`}
                          onClick={(e) => handleClick(e)}
                          name="edit"
                          id={category._id}
                        >
                          {category.name}
                        </a>
                      </Can>
                    </li>
                  );
                })
            ) : (
              <div>No content. Please add!</div>
            )
          ) : (
            <div style={{ marginTop: `50px` }} className="loader" />
          )}
        </ul>
      </Fragment>
    );
  }
);

ListFinancingRequests.propTypes = {
  classes: PropTypes.object.isRequired,
};
