import React, { createContext, useContext } from "react";

export const AlertManagerContext = createContext();

export const AlertManager = (props) => {
  return (
    <AlertManagerContext.Consumer>
      {props.children}
    </AlertManagerContext.Consumer>
  );
};

export const useAlertManager = () => useContext(AlertManagerContext);
