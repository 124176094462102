import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Radio, RadioGroup } from '@blueprintjs/core';
import styles from '../contact/form.styles';
import BootstrapGridColumn from '../forms/form.grid.column';
import { BootsrapTextField } from '../forms/form.bootstrap.field';
import { useAuth } from '../user/access/user.permissions';
import CustomCKEditor from '../ckeditor/editor.component';

/**
 * A multi-step form component for the user to fill when applying or 
 * placing a request for financing support.
 * 
 * @author Isaac S. Mwakabira
 * 
 */
const FinancingRequestSupport = ({
    classes, 
    handleClick,
    capitalize,
    link,
    createCategory,
    maincategory,
    userCanPublish
}) => {
    const [subcategory, setSubcategory] = useState()
    const [editorText, setEditorText] = useState()
    const [shortname, setShortname] = useState()
    const [radio, setRadio] = useState('isDraft')

    const auth = useAuth()
    const roles = Object.keys(auth?.user.roles)[0]

    const handleRadioChange = (e) => setRadio(e.target.value)

    const handleSubmit = (event) => {
        // prevent default behaviour
        event.preventDefault();
        // get authenticated user token
        if (auth?.user?.token) {
            let sub_category;
            if (subcategory && shortname && editorText && radio) {
                // define sub-category structure
                sub_category = {
                    name: subcategory,
                    shortName: shortname,
                    about: editorText,
                    isDraft: true
                }
                
                // only users with admin/publisher roles are allowed to edit this property
                if (userCanPublish) {
                    sub_category.isPublished= radio === 'isPublished' ? true : false
                } else {
                    sub_category.isDraft= radio === 'isDraft' ? true : false
                }
                
                createCategory(
                    maincategory._id,// category under which this subcategory should be uploaded to
                    sub_category, 
                    auth?.user?.token,
                    capitalize(link)
                );
            }
        }
    }

    return (
        <Fragment>
            <h5 className='heading-text'>Create financing process steps</h5>

            <form onSubmit={(e) => handleSubmit(e)} autoComplete="off">
                <div className='margin-fix form-row'>
                    <BootstrapGridColumn>
                        <BootsrapTextField
                            value={subcategory}
                            name="subcategory"
                            label="Name*"
                            placeholder="New step name..."
                            type="text"
                            handleChange={e=>setSubcategory(e.target.value)}
                        />
                    </BootstrapGridColumn>
                    <BootstrapGridColumn>
                        <BootsrapTextField
                            name="shortname"
                            type="text"
                            placeholder="New step shortname..."
                            label="Shortname*"
                            value={shortname}
                            handleChange={e=>setShortname(e.target.value)}
                        />
                    </BootstrapGridColumn>
                </div>

                <CustomCKEditor
                    label="Summary Text*"
                    editorText={editorText}
                    setEditorText={editor => setEditorText(editor.getData())}
                />

                <RadioGroup
                    label="Publication Status*"
                    onChange={e=>handleRadioChange(e)}
                    selectedValue={radio}
                >
                    <Radio 
                        label='Draft' 
                        value="isDraft" 
                    />
                    <Radio 
                        label='Published' 
                        value="isPublished" 
                        disabled={roles !== 'writer' ? false : true}
                    />
                </RadioGroup>

                <Button 
                    icon='new-object'
                    type="submit" 
                    disabled={!(subcategory && shortname && editorText && radio)} 
                    intent="success" 
                    text="Save step" 
                />

                <Button 
                    icon='arrow-left'
                    className={classes.margin} 
                    name="default" 
                    intent="primary" 
                    text="Cancel" 
                    onClick={e => handleClick(e)} 
                />
            </form>
        </Fragment>
    );
}

FinancingRequestSupport.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default (withStyles(styles)(FinancingRequestSupport));