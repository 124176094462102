
export const CMSType = {

    // categories
    REQUEST_CATEGORY: "REQUEST_CATEGORY",
    REQUEST_ADD_CATEGORY: "REQUEST_ADD_CATEGORY",
    REQUEST_SUB_CATEGORY: "REQUEST_SUB_CATEGORY",
    REQUEST_ADD_SUB_CATE: "REQUEST_ADD_SUB_CATE",
    REQUEST_EDIT_SUB_CATE: "REQUEST_EDIT_SUB_CATE",
    REQUEST_DELETE_SUB_CATE: "REQUEST_DELETE_SUB_CATE",
    // stakeholders
    REQUEST_ADD_STAKEHOLDER: "REQUEST_ADD_STAKEHOLDER",
    REQUEST_EDIT_STAKEHOLDER: "REQUEST_EDIT_STAKEHOLDER",
    REQUEST_DELETE_STAKEHOLDER: "REQUEST_DELETE_STAKEHOLDER",
    REQUEST_LIST_STAKEHOLDER: "REQUEST_LIST_STAKEHOLDER",
    REQUEST_A_STAKEHOLDER: "REQUEST_A_STAKEHOLDER",
    REQUEST_ADD_STAKEHOLDER_IMAGE: "REQUEST_ADD_STAKEHOLDER_IMAGE",
    // fqas
    REQUEST_FA_QUESTION: "REQUEST_FA_QUESTION"
    
}