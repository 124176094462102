import React, { useEffect, } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Container, Card, CardBody, Row } from 'reactstrap'
import { withStyles } from '@material-ui/core';
import ParticlesComponent from '../user/particles';
import * as CMSAction from '../../actions/cms.action';
import { NoDataCard } from '../card.text';
import { Intent } from '@blueprintjs/core';
import CustomColumn from '../news/custom.column';

/**
 * Renders financing component
 * 
 * @author Paul Sembereka (Pablo)
 * @author Isaac S. Mwakabira
 * 
 */
const Financing = ({
    maincategory, 
    general, 
    classes,
    category: getCategory
}) => {
    useEffect(() => {
        getCategory('Financing')
    }, [getCategory])

    const item = maincategory?.subCategories[0]
    // const published = items?.filter(item => item?.isPublished)

    return (
        <div className="page-content">
            <ParticlesComponent />
            <Container>
                <Row>
                    {
                        !general?.isLoading ? ( item?.isPublished ?
                                <Card>
                                    <CardBody>
                                        <p className={classes.header}>
                                            <strong>
                                                {item?.name}
                                            </strong>
                                        </p>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: item?.about
                                            }}
                                        />
                                    </CardBody>
                                </Card>
                                : <CustomColumn sm='12' md='12' lg='12'>
                                    <Card>
                                        <NoDataCard
                                            text={`No information to show`}
                                            header={`Information!`}
                                            intent={Intent.SUCCESS}
                                        />
                                    </Card>
                                </CustomColumn>
                            )
                        : <p className="loader" />
                    }
                </Row>
            </Container>
        </div>
    );
}

const styles = {
    header: {
        textAlign: 'left',
    },
    financing: {
        marginBottom: 8,
        borderRadius: 0
    }
}

const mapStateToProps = (state) => {
    return {
        general: state.general.general,
        maincategory: state.cms.maincategory,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        category: (name) => { dispatch(CMSAction.fetchCategory(name)) }, // Financing
    };
}

Financing.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Financing));
