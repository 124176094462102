import React, { useState } from 'react'
import { AlertManagerContext } from './alert.context'

export default function AlertManagerProvider(props) {
  const [isAlertOpen, setAlertIsOpen] = useState(false)

  return (
    <AlertManagerContext.Provider value={{
        isAlertOpen, setAlertIsOpen,
        ...props
    }}>
      {props.children}
    </AlertManagerContext.Provider>
  );
}

