import React, { Fragment, useState } from "react";
import { Intent } from "@blueprintjs/core";
import { NoDataCard } from "../card.text";
import { withStyles, Tooltip, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import styles from "../contact/form.styles";
import { Can, useAuth } from "../user/access/user.permissions";
import { ToolbarManager } from "../cms/common/toolbar.manager";
import { algorithms } from "../common/algorithms";

const VALUES = ["all"];

/**
 * List of all news items
 *
 * @author Isaac S. Mwakabira
 *
 * @param {Props} Props
 */
const ListNewsArticles = ({ articles, handleClick, general, classes }) => {
  const [filters, setFilters] = useState(VALUES);

  const auth = useAuth();

  return (
    <Fragment>
      <ToolbarManager
        filters={filters}
        setFilters={setFilters}
        handleClick={handleClick}
        classes={classes}
      >
        <Can I="create" a="News">
          <Fragment>
            <Tooltip title="Create new article">
              <Button name="create" onClick={(e) => handleClick(e)}>
                <AddIcon /> Create new article
              </Button>
            </Tooltip>
          </Fragment>
        </Can>
      </ToolbarManager>

      <div className={classes.margin} />

      <ul>
        {!general?.isLoading ? (
          articles?.length > 0 ? (
            articles
              ?.filter((object) => algorithms.filterByUserAbility(object, auth))
              ?.filter((article) =>
                algorithms.filterByProperty(filters, article)
              )
              .map(({ _id, title }, index) => {
                return (
                  <li key={index}>
                    <Can I="update" a="News" passThrough>
                      {(allowed) =>
                        !allowed ? (
                          <a href="#/">{title}</a>
                        ) : (
                          <a
                            name="edit"
                            id={_id}
                            key={_id}
                            href={`${"/news/" + title}`}
                            onClick={(e) => handleClick(e)}
                          >
                            {title}
                          </a>
                        )
                      }
                    </Can>
                  </li>
                );
              })
          ) : (
            <NoDataCard
              header={`No articles`}
              style={{ marginTop: `30px` }}
              intent={Intent.WARNING}
            />
          )
        ) : (
          <div className="loader" />
        )}
      </ul>
    </Fragment>
  );
};

export default withStyles(styles)(ListNewsArticles);
