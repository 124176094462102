import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import EditNewsItem from '../news/news.edit.item';
import EditLibraryItem from '../library/library.edit.document';
import CreateNewsItem from '../news/news.create.item';
import CreateLibraryItem from '../library/library.create.document';
import ResourceSection from './cms.section.resource';
import ListNewsArticles from '../news/news.list.items';
import ListLibraryDocuments from '../library/library.list.documents';
import { ListDirectoryInstitution } from '../directory/directory.list.institutions';
import EditDirectoryInstitution from '../directory/directory.edit.institution';
import CreateDirectoryInstitution from '../directory/directory.create.institution';
import FinancingRequestSupport from '../financing/financing.request.support';
import UserProfile from '../user/user.profile';
import { redirect } from '../user/user.redirect';
import { ListHomeSubcategory } from '../home/home.list.subcategory';
import EditHomeSubcategory from '../home/home.edit.subcategory';
import CreateHomeSubcategory from '../home/home.add.subcategory';
import { ListFinancingRequests } from '../financing/financing.list.requests';
import EditFinancingRequestSupport from '../financing/financing.edit.request';
import AddFeature from '../gis/gis.cms.add_feature';
import ListUserAccounts from '../user/user.list.cms.component';
import EditUserAccount from '../user/user.edit.cms.component';
import EditUserProfile from '../user/user.details.cms.component';
import ListFAQS from '../faq/faqs.list.component';
import CreateQuestion from '../faq/faq.add.component';
import EditQuestion from '../faq/faq.edit.component';
import { ListLicensing } from '../licensing/licensing.list';
import CreateLicensingStep from '../licensing/licensing.create';
import EditLicensingStep from '../licensing/licensing.edit';
import AddUserAccount from '../user/user.add.component';
import GisFeatures from '../gis/gis.cms.features';
import { Can } from '../user/access/user.permissions';

/**
 * Renders a single section resource i.e. licencing, library at cms index
 * which can be edited, published or unpublished, archived or deleted
 * 
 * @author Isaac S. Mwakabira
 * 
 * @param {Component} link 
 * @param {Props} props 
 * @param {Function} handleClick 
 * @param {Function} handleChange
 * 
 * @returns {Component} resource  
 */
const RenderSection = ({
    link,
    props,
    handleClick,
    handleChange,
    category,
    district_name,
    region_name,
    capitalize,
    handleFilteredResource,
    filteredResource
}) => {

    switch (link) {

        case 'home':
            
            return (
                <Fragment>

                    <ResourceSection
                        option={props.user_event}
                        name="home"
                        list={() => <ListHomeSubcategory
                                {...props}
                                handleClick={(e) => handleClick(e)}
                                category={props.home}
                                capitalize={capitalize}
                                handleChange={(e) => { handleChange(e) }}
                            />
                        }
                        edit={() => <Can I='update' a='Category' field='isPublished' passThrough>
                            { allowed => <EditHomeSubcategory
                                handleClick={(e) => handleClick(e)}
                                category={props.home}
                                subcategory={props.subcategory}
                                editCategory={props.editCategory}
                                archiveCategory={props.archiveCategory}
                                {...props}
                                link={link}
                                userCanPublish={allowed}
                                capitalize={capitalize}
                            />}
                        </Can>
                        }
                        create={() => <Can I='create' a='Category' field='isPublished' passThrough>
                            { allowed => <CreateHomeSubcategory
                                handleClick={(e) => handleClick(e)}
                                category={props.home}
                                createCategory={props.createCategory}
                                props={props}
                                link={link} 
                                userCanPublish={allowed}
                                capitalize={capitalize}
                            />}
                        </Can>
                        }
                    />

                </Fragment>
            );

        case 'licensing':

            return (
                <Fragment>

                    <ResourceSection
                        option={props.user_event}
                        name="licensing"
                        list={() => <ListLicensing
                            {...props}
                            handleClick={(e) => handleClick(e)}
                        />
                        }
                        create={() => <Can I='create' a='Category' field='isPubblished' passThrough>
                            { allowed => <CreateLicensingStep
                                handleClick={(e) => handleClick(e)}
                                handleChange={(e) => { handleChange(e) }}
                                {...props}
                                userCanPublish={allowed}
                                link={link} 
                                capitalize={capitalize}
                            />}
                        </Can>
                        }
                        edit={() => <Can I='update' a='Category' field='isPubblished' passThrough>
                            {allowed => <EditLicensingStep
                                {...props}
                                category={category}
                                handleClick={(e) => handleClick(e)}
                                handleChange={(e) => { handleChange(e) }}
                                link={link}
                                userCanPublish={allowed}
                                capitalize={capitalize}
                            />}
                        </Can>
                        }
                    />
                </Fragment>
            );

        case 'financing':

            return (
                <Fragment>

                    <ResourceSection
                        option={props.user_event}
                        name="financing"
                        list={() => <ListFinancingRequests
                            {...props}
                            handleClick={(e) => handleClick(e)}
                            handleChange={(e) => handleChange(e)}
                            maincategory={props.maincategory}
                        />
                        }
                        edit={() => <Can I='update' a='Category' field='isPublished' passThrough>
                            { allowed => <EditFinancingRequestSupport
                                handleClick={(e) => handleClick(e)}
                                editCategory={props.editCategory}
                                {...props}
                                link={link}
                                userCanPublish={allowed}
                                capitalize={capitalize}
                            />}
                        </Can>
                        }
                        create={() => <Can I='create' a='Category' field='isPublished' passThrough>
                            {allowed => <FinancingRequestSupport
                                handleClick={(e) => handleClick(e)}
                                maincategory={props?.maincategory}
                                createCategory={props?.createCategory}
                                {...props}
                                link={link}
                                userCanPublish={allowed}
                                capitalize={capitalize}
                            />}
                        </Can>
                        }
                    />

                </Fragment>
            );

        case 'gis':
            return (
                <Fragment>

                    <ResourceSection
                        option={props.user_event}
                        name="gis"
                        list={() => <GisFeatures
                            {...props}
                            district_name={district_name}
                            region_name={region_name}
                            handleClick={(e) => handleClick(e)}
                            handleChange={(e) => handleChange(e)}
                        />

                        }
                        create={() => <AddFeature 
                            {...props} 
                            handleClick={(e) => handleClick(e)} 
                            link={link} 
                            capitalize={capitalize} />
                        }
                    />

                </Fragment>
            );

        case 'directory':

            return (
                <Fragment>

                    <ResourceSection
                        option={props.user_event}
                        name="directory"
                        list={() => <ListDirectoryInstitution
                            handleClick={(e) => handleClick(e)}
                            stakeholders={props.stakeholders_list}
                            handleChange={(e) => { handleChange(e) }}
                            {...props}
                        />
                        }
                        edit={() => <Can I='update' a='Stakeholder' passThrough>
                            { allowed => <EditDirectoryInstitution
                                handleClick={(e) => handleClick(e)}
                                stakeholder={props.stakeholder}
                                editStakeholder={props.editStakeholder}
                                // archiveStakeholder={ props.archiveStakeholder }
                                {...props}
                                link={link}
                                userCanPublish={allowed}
                                capitalize={capitalize}
                            />}
                        </Can>
                        }
                        create={() => <Can I='update' a='Stakeholder' passThrough>
                            { allowed => <CreateDirectoryInstitution
                                stakeholder={props.stakeholder}
                                createStakeholder={props.createStakeholder}
                                {...props}
                                handleClick={(e) => handleClick(e)}
                                userCanPublish={allowed}
                                link={link} capitalize={capitalize}
                            />}
                        </Can>
                        }
                    />

                </Fragment>
            );

        case 'library':

            return (
                <Fragment>

                    <ResourceSection
                        option={props.user_event}
                        name="library"
                        list={() => <ListLibraryDocuments
                            handleClick={(e) => handleClick(e)}
                            docs={props.library}
                            {...props}
                            handleChange={(e) => handleChange(e)}
                            filteredResource={filteredResource}
                            handleFilteredResource={ handleFilteredResource }
                        />
                        }
                        edit={() => <Can I='update' a='Category' field='isPublished' passThrough>
                            { allowed => <EditLibraryItem
                                handleClick={(e) => handleClick(e)}
                                {...props}
                                link={link}
                                capitalize={capitalize}
                                userCanPublish={allowed}
                                filteredResource={ filteredResource }
                            />}
                        </Can>
                        }
                        create={() => <Can I='create' a='Category' field='isPublished' passThrough>
                            {allowed => <CreateLibraryItem
                                handleClick={(e) => handleClick(e)}
                                {...props}
                                uploadFile={props.uploadFile}
                                userCanPublish={allowed}
                                handleChange={(e) => handleChange(e)}
                                link={link} capitalize={capitalize}
                            />}
                        </Can>
                        }
                    />

                </Fragment>
            );

        case 'faqs':

            return (
                <ResourceSection
                    option={props.user_event}
                    name="faqs"
                    list={
                        () => <ListFAQS
                            handleClick={(e) => handleClick(e)}
                            {...props}
                            questions={props.maincategory}
                        />
                    }
                    create={
                        () => <Can I='create' a='Category' field='isPublished' passThrough>
                            {allowed => <CreateQuestion
                                handleClick={(e) => handleClick(e)}
                                handleChange={(e) => handleChange(e)}
                                {...props}
                                link={link} 
                                userCanPublish={allowed}
                                capitalize={capitalize}
                            />
                        }
                        </Can>
                    }
                    edit={
                        () => <Can I='update' a='Category' field='isPublished' passThrough>
                            { allowed => <EditQuestion
                                handleClick={(e) => handleClick(e)}
                                handleChange={(e) => handleChange(e)}
                                {...props}
                                link={link}
                                userCanPublish={allowed}
                                capitalize={capitalize}
                            />}
                        </Can>
                    }
                />
            );

        case 'news':
            
            return (
                <Fragment>

                    <ResourceSection
                        option={props.user_event}
                        name="news"
                        list={() => <ListNewsArticles
                            handleClick={(e) => handleClick(e)}
                            handleChange={e => handleChange(e)}
                            {...props}
                        />
                        }
                        edit={() => <Can I='update' a='News' field='isPublished' passThrough>
                                { allowed => <EditNewsItem 
                                    {...props} 
                                    handleClick={(e) => handleClick(e)} 
                                    link={link} 
                                    capitalize={capitalize}
                                    userCanPublish={allowed}
                                /> }
                            </Can>
                        }
                        create={() => <Can I='create' a='News' field='isPublished' passThrough>
                            { allowed => <CreateNewsItem 
                                handleClick={(e) => handleClick(e)} 
                                {...props} 
                                link={link} 
                                capitalize={capitalize} 
                                userCanPublish={allowed}
                            />}
                        </Can>
                        }
                    />

                </Fragment>
            );

        case 'logout':

            // Get logged in user, then remove from local storage
            const user = UserProfile.get();
            if (user !== undefined && user !== null) {
                // logout user
                props.logout(user);
                // redirect the user to login
                return redirect.to({ url: `/login` });

            } else {

                return <Fragment />

            }

        case 'accounts':

            return (
                <Fragment>

                    <ResourceSection
                        option={props.user_event}
                        name="accounts"
                        list={
                            () => <ListUserAccounts
                                {...props}
                                link={link}
                                handleAccountClick={(e) => handleClick(e)}
                            />
                        }
                        create={() => <AddUserAccount
                            {...props}
                            handleClick={handleClick}
                            handleChange={handleChange}
                            link={link} capitalize={capitalize}
                        />
                        }
                        edit={
                            () => <EditUserAccount
                                {...props}
                                handleClick={handleClick}
                                handleChange={handleChange}
                                link={link} capitalize={capitalize}
                            />
                        }
                    />

                </Fragment>
            );

        case 'profile':

            return (
                <Fragment>
                    <ResourceSection
                        option={props.user_event}
                        name="profile"
                        list={() => <EditUserProfile
                            {...props}
                            handleClick={(e) => handleClick(e)}
                            handleChange={e => handleChange(e)}
                        />
                        }
                    />
                </Fragment>
            );

        default: {
            return <Fragment />
        }

    }
}

const styles = theme => ({
    content: {
        height: `100%`,
        width: `80%`,
        flexGrow: 1,
        padding: theme.spacing.unit * 0,
        minWidth: 0, // So the Typography noWrap works
    },
});


RenderSection.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(RenderSection);