import React from "react";

/**
 * Renders a single resource
 * 
 * @author Isaac S. Mwakabira
 * 
 * @param {String} name
 * @param {String} option 
 * @param {Component} list
 * @param {Component} create
 * @param {Component} edit
 * @param {Component} remove
 * 
 * @returns {ResourceSection} component
 */
const ResourceSection = ({ 
    option, 
    name, 
    list: List, 
    create: Create, 
    edit: Edit,
}) => {

    // return if name is null and undefined
    if(name === null && name === undefined) {
        return;
    }

    // return if option is null and undefined
    if(option === null && option === undefined) {
        return;
    }

    /** 
     * removes any leading and trailling white space 
     * characters from the name string
     */
    const section = name?.trim();

    // if no section is defined, just return
    if(!section) return;

    switch (option?.trim()) {

        case 'list':
            // list of resource chosen
            return <List />

        case 'create':
            // create new resource
            return <Create />

        case 'edit':
            // edit
            return <Edit />
        
        default:
            // default, list all
            return <List />
    }

}

export default ResourceSection;