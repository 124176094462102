import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { reduxForm } from 'redux-form';
import AsyncValidate from '../contact/form.async-validate';
import Validate from '../contact/email.validate';
import { Button, RadioGroup, Radio } from '@blueprintjs/core';
import styles from '../contact/form.styles';
import UserProfile from '../user/user.profile';
import BootstrapGridColumn from '../forms/form.grid.column';
import { BootsrapTextField } from '../forms/form.bootstrap.field';
import CustomCKEditor from '../ckeditor/editor.component';
import { Can } from '../user/access/user.permissions';
import ConfirmActionManager from '../common/alerts/confirmation.alert';

/**
 * A multi-step form component for the user to fill when applying or 
 * placing a request for financing support or edit an already placed request.
 * 
 * @author Isaac S. Mwakabira
 * 
 */
class EditFinancingRequestSupport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAlertOpen: false
        }

        /**
         * Bind events to each Function, so that they can be passed without args 
         * i.e this.handleChange
         * 
         */
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAlertConfirm = this.handleAlertConfirm.bind(this)
        this.handleRadioChange = this.handleRadioChange.bind(this)
    }

    handleRadioChange = (e) => {
        this.setState({ radio: e.target.value })
    }

    componentDidMount() {
        this.setState({
            radio: this.props?.subcategory?.isPublished ? 'isPublished' : 'isDraft'
        })
    }

    /**
	 * On change, update the app's React state with event type value.
	 *
	 * @param {Event} event
	 */
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    setEditorText = (editor) => {
        this.setState({ editorText: editor.getData() });
    }

    handleSubmit = (event) => {
        // prevent default behaviour
        event.preventDefault();
        // category under which this subcategory should 
        // be uploaded to
        const { maincategory, userCanPublish } = this.props;
        const { subcategory, shortname, editorText, radio } = this.state;
        // get authenticated user token
        const user = UserProfile.get();
        if (user?.token) {
            let sub_category;
            if (subcategory || shortname || editorText || radio) {
                // define sub-category structure
                sub_category = {
                    name: subcategory,
                    shortName: shortname,
                    about: editorText,
                    isDraft: radio === 'isDraft' ? true : false
                }

                // only users with admin/publisher roles are allowed to edit this property
                if (userCanPublish) {
                    sub_category.isPublished= radio === 'isPublished' ? true : false
                }

                this.props.editCategory(
                    this.props.subcategory._id, 
                    sub_category, 
                    user.token,
                    maincategory,
                    this.props.capitalize(this.props.link)
                );
            }
        }
    }

    /**
     * Delete category
     */
    archiveCategory = (event) => {
        event.preventDefault();
        // props holds state functions like defaultItem(), saveItem() etc 
        const { subcategory } = this.props;
        // if subcategory exists then delete
        if (subcategory?._id) {
            // then get authenticated user token
            const user = UserProfile.get();
            if (user?.token) {
                this.props.archiveCategory(
                    subcategory, 
                    user?.token, 
                    this.props.capitalize(this.props.link)
                );
            }
        }
    }

    handleAlertIsOpen = (e, toggledState) => {
        e.preventDefault()
        this.setState({ isAlertOpen: toggledState })
    }

    handleAlertConfirm = e => {
        e.preventDefault()
        // handle deletion here and close dialog box
        this.archiveCategory(e)
        this.handleAlertIsOpen(e, !this.state?.isAlertOpen)
    }

    render() {
        const { classes, handleClick, general, userCanPublish } = this.props;

        // state
        const { subcategory, shortname, editorText, radio } = this.state;

        return (
            <Fragment>
                <h5 className='heading-text'>Edit a financing step</h5>

                <ConfirmActionManager 
                    alertMessage={() => {
                        return <Fragment>
                            Are you sure you want to delete <b>{
                                `${ this.props?.subcategory?.name }`
                            }</b>? You will not be able to restore this item later.
                        </Fragment>
                    }}
                    cancelButtonText="Cancel deletion"
                    confirmButtonText="Confirm deletion"
                    isAlertOpen={this.state?.isAlertOpen} 
                    handleAlertCancel={e => this.handleAlertIsOpen(e, !this.state.isAlertOpen)}
                    handleAlertConfirm={this.handleAlertConfirm}
                />

                <form onSubmit={(e) => this.handleSubmit(e)} autoComplete="off">
                    {
                        !general?.isLoading ? (
                            <Fragment>
                                <div className='margin-fix form-row'>
                                    <BootstrapGridColumn>
                                        <BootsrapTextField
                                            value={
                                                this.props.subcategory 
                                                ? (subcategory 
                                                    ? subcategory : this.props?.subcategory.name) : ''
                                            }
                                            name='subcategory'
                                            id="subcategory"
                                            label='Name'
                                            placeholder="Edit sub-category name..."
                                            handleChange={this.handleChange}
                                        />
                                    </BootstrapGridColumn>
                                    <BootstrapGridColumn>
                                        <BootsrapTextField
                                            name="shortname"
                                            label="Shortname"
                                            placeholder="Edit sub-category shortname..."
                                            value={
                                                this.props.subcategory 
                                                ? (shortname 
                                                    ? shortname : this.props.subcategory.shortName) : ''
                                            }
                                            type="text"
                                            handleChange={this.handleChange}
                                        />
                                    </BootstrapGridColumn>
                                </div>

                                <CustomCKEditor
                                    label="Summary"
                                    editorText={
                                        this.props.subcategory 
                                        ? (editorText ? editorText :  this.props.subcategory.about) : ''
                                    }
                                    setEditorText={this.setEditorText}
                                />

                                <RadioGroup
                                    label="Publication Status*"
                                    onChange={e=>this.handleRadioChange(e)}
                                    selectedValue={radio}
                                >
                                    <Radio 
                                        label='Draft' 
                                        value="isDraft" 
                                    />
                                    <Radio 
                                        label='Published' 
                                        value="isPublished" 
                                        disabled={userCanPublish ? false : true}
                                    />
                                </RadioGroup>

                                <Button
                                    icon='new-object'
                                    type="submit"
                                    disabled={!(subcategory || shortname || editorText || radio)}
                                    intent="success"
                                    text="Save changes"
                                />

                                <Can I='delete' a='Category'>
                                    <Button
                                        icon='delete'
                                        className={classes.margin}
                                        intent="danger" 
                                        text="Delete item"
                                        disabled={general?.isLoading}
                                        onClick={(e) => this.handleAlertIsOpen(e, 
                                            !this.state.isAlertOpen)
                                        }
                                    />
                                </Can>

                                <Button
                                    icon='arrow-left'
                                    name="default"
                                    intent="primary"
                                    text="Cancel"
                                    onClick={e => handleClick(e)}
                                />
                            </Fragment>
                        ) : <div className="loader" />
                    }
                </form>
            </Fragment>
        );
    }
}

EditFinancingRequestSupport.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default reduxForm({
    form: 'editFinansingSupport',
    Validate,
    AsyncValidate
})(withStyles(styles)(EditFinancingRequestSupport));