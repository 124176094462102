import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Radio, RadioGroup } from '@blueprintjs/core';
import { Paper, FormControl } from '@material-ui/core';
import styles from '../contact/form.styles';
import UserProfile from '../user/user.profile';
import { SelectInputControl } from '../forms/form.selectinput.field';
import BootstrapGridColumn from '../forms/form.grid.column';
import { BootsrapTextField } from '../forms/form.bootstrap.field';
import { BootsrapTextareaField } from '../forms/form.textarea.field';
import Toast from '../../toastfy';
import CustomCKEditor from '../ckeditor/editor.component';
import { Can } from '../user/access/user.permissions'
import ConfirmActionManager from '../common/alerts/confirmation.alert';

/**
 * Add new question sections(general categories)
 * Add new questions under a section
 * 
 * @author Isaac S. Mwakabira
 */
class CreateQuestion extends Component {
    constructor() {
        super();
        this.state = {
            add_section: false,
            edit_section: false,
            add_category: false,
            isAlertOpen: false,
            radio: 'isDraft'
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleTextChange = this.handleTextChange.bind(this)
        this.handleToggleChecked = this.handleToggleChecked.bind(this)
        this.handleAlertIsOpen = this.handleAlertIsOpen.bind(this)
        this.handleRadioChange = this.handleRadioChange.bind(this)
    }

    handleRadioChange = (e) => {
        this.setState({ radio: e.target.value })
    }

    /**
     * On event change, get field name and value, set state
     */
    handleTextChange = (event) => {
        this.setState({ 
            [event.target.name]: event.target.value 
        });
    }

    setEditorText = (editor) => {
        this.setState({ 
            editorText: editor.getData() 
        });
    }

    handleToggleChecked = e => this.setState({
        ...this.state, 
        isDraft: e.checked
    })

    // choosen section
    handleChange = (e) => {
        // if chosen section
        if (e.target.name) {
            const sectionName = e.target.value;
            const sections = this.props.maincategory;

            // if sections not null
            if (sections !== null) {
                // then iterate through the subcategories
                // and filter the chosen section
                const filteredSection = sections?.subCategories?.filter(section => {
                    if (sectionName !== null && section !== null) {
                        // check if the chosen section from the drop down list
                        // equals one of the sections/subCategories
                        // in the Frequently asked questions
                        if (section.name === sectionName) {
                            return section;
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                });

                // was anything returned
                if (filteredSection) {
                    this.setState({ 
                        [e.target.name]: filteredSection[0], 
                        add_category: false 
                    });
                }
            }
        }
    }

    handleAddSection = (event) => {
        // prevent default events
        event.preventDefault();
        // if add_section if false
        // then set it to true else false
        this.setState({ 
            add_section: true, 
            edit_section: false 
        })
    }

    handleEditSection = (event) => {
        // prevent default events
        event.preventDefault();
        // if edit_resource if false
        // then set it to true else false
        this.setState({ 
            edit_section: true, 
            add_section: false 
        })
    }

    handleDeleteSection = (event) => {
        // prevent default events
        event.preventDefault();
        // get authenticated user token
        const user = UserProfile.get();
        this.props.archiveResourceCategory(
            this.state.section,
            user.token,
            this.props.capitalize(this.props.link)
        );
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { userCanPublish } = this.props
        // section under which this question should 
        // be uploaded to
        const { section, radio } = this.state;
        const {
            _question, editorText, shortname, 
            section_name, section_short_name, section_summary,
            resource_name, resource_short_name, resource_summary
        } = this.state;

        const emptyQFields = _question && editorText && shortname ? false : true;
        const emptySFields = section_name && section_short_name && section_summary ? false : true;

        // get authenticated user token
        const user = UserProfile.get();
        if (user !== null && user.token !== undefined) {

            let edited_question;
            // check if resource or file if being added
            if (this.state.add_section) {
                // we are adding a section category: sub-category essentially
                // define object structure
                const add_section = {
                    name: section_name,
                    shortName: section_short_name,
                    about: section_summary,
                    isDraft: radio === 'isDraft' ? true : false
                }

                if (userCanPublish) {
                    add_section.isPublished= radio === 'isPublished' ? true : false
                } else {
                    add_section.isDraft= radio === 'isDraft' ? true : false
                }

                // category to add sections to: Faqs
                const { maincategory } = this.props;
                // then check if null and undefined, then proceed otherwise
                if (maincategory !== null && maincategory !== undefined && !emptySFields) {
                    // create new section category
                    this.props.addResourceCategory(
                        maincategory._id,
                        add_section,
                        user.token,
                        this.props.capitalize(this.props.link)
                    );
                    // then change state.add_section to false
                    // so that the page shows form fileds to add questions
                    this.setState({ add_section: false, add_category: true });
                }
            } else if (this.state.edit_section) {
                // we are editing a resource category: sub-category essentially
                // define file structure
                const edited_section = {
                    name: resource_name,
                    shortName: resource_short_name,
                    about: resource_summary,
                    isDraft: radio === 'isDraft' ? true : false
                }

                if (userCanPublish) {
                    edited_section.isPublished= radio === 'isPublished' ? true : false
                } else {
                    edited_section.isDraft= radio === 'isDraft' ? true : false
                }

                // category to add sections to: Faqs
                const { maincategory } = this.props;

                if (maincategory !== null && maincategory !== undefined) {
                    // make request
                    this.props.editResourceCategory(
                        section._id, // resource to be edited
                        edited_section, // edited params
                        user.token, // authenticated account
                        this.props.capitalize(this.props.link)
                    );
                    // then change state.edit_resource to false
                    // so that the page shows form fields to add files and 
                    // supporting documents
                    this.setState({ edit_resource: false, add_category: true });
                }
            } else {
                // define edited_question structure
                edited_question = {
                    name: _question,
                    shortName: shortname,
                    about: editorText,
                    isDraft: true
                }

                if (userCanPublish) {
                    edited_question.isPublished= radio === 'isPublished' ? true : false
                } else {
                    edited_question.isDraft= radio === 'isDraft' ? true : false
                }

                if (section) {
                    if (!emptyQFields) {
                        // create new question under section selected
                        this.props.createCategory(
                            section._id,
                            edited_question,
                            user.token,
                            this.props.capitalize(this.props.link)
                        );
                    }
                } else {
                    // toast message for user feedback
                    Toast.emit({
                        type: Toast.TYPES.ERROR,
                        message: `Please make sure all fields are filled!!`
                    })
                }
            }
        }
    }

    addSection = () => {
        const { classes, userCanPublish } = this.props;
        const {
            section_name, 
            section_short_name, 
            section_summary,
            radio
        } = this.state;

        const emptySFields = section_name && section_short_name && section_summary ? false : true;

        const roles = Object.keys(UserProfile.get().roles)[0]

        return (
            <Fragment>
                <div className='margin-fix form-row'>
                    <BootstrapGridColumn>
                        <BootsrapTextField
                            value={this.state.section_name}
                            name='section_name'
                            label="Section*"
                            placeholder="Enter section name..."
                            type="text"
                            handleChange={this.handleTextChange}
                        />
                    </BootstrapGridColumn>
                    <BootstrapGridColumn>
                        <BootsrapTextField
                            name="section_short_name"
                            label="Shortname*"
                            placeholder="Enter section shortname..."
                            type="text"
                            value={this.state.shortName}
                            handleChange={this.handleTextChange}
                        />
                    </BootstrapGridColumn>
                </div>

                <div className="form-group">
                    <BootsrapTextareaField
                        name="section_summary"
                        value={this.state.question}
                        placeholder="Enter section summary..."
                        label="Summary*"
                        type="text"
                        rows={10}
                        handleChange={this.handleTextChange}
                    />
                </div>

                <div className={classes.margin} />
                <div className={classes.margin} style={{ marginBottom: '1.5em' }} />

                <RadioGroup
                    label="Publication Status*"
                    onChange={this.handleRadioChange}
                    selectedValue={radio}
                >
                    <Radio 
                        label='Draft' 
                        value="isDraft" 
                    />
                    <Radio 
                        label='Published' 
                        value="isPublished" 
                        disabled={roles !== 'writer' ? false : true}
                    />
                </RadioGroup>

                <Button icon='new-object'
                    type="submit" 
                    disabled={emptySFields}
                    intent="success" 
                    text={`Save ${ this.state.add_section ? 'section' : 'question' }`} 
                />

                <Button icon='arrow-left'
                    className={classes.margin} 
                    name="default" 
                    intent="primary" 
                    text="Cancel" 
                    onClick={() => {
                        if (this.state.add_section) {
                            this.setState({ add_section: false, add_category: true })
                        }
                    }} 
                />
            </Fragment>
        );

    }

    editSection = () => {
        const { classes, userCanPublish } = this.props;
        const { section, resource_name, resource_short_name, resource_summary, radio } = this.state;

        return (
            <Fragment>
                <div className='margin-fix form-row'>
                    <BootstrapGridColumn>
                        <BootsrapTextField
                            value={section ? (resource_name ? resource_name : section.name) : null}
                            name='resource_name'
                            label="Section*"
                            placeholder="Edit section name..."
                            type="text"
                            handleChange={this.handleTextChange}
                        />
                    </BootstrapGridColumn>
                    <BootstrapGridColumn>
                        <BootsrapTextField
                            name="resource_short_name"
                            placeholder="Edit section shortname..."
                            type="text"
                            label="Shortname*"
                            value={section ? (resource_short_name ? resource_short_name : section.shortName) : null}
                            handleChange={this.handleTextChange}
                        />
                    </BootstrapGridColumn>
                </div>

                <div className="form-group">
                    <BootsrapTextareaField
                        value={section ? (resource_summary ? resource_summary : section.about) : null}
                        name='resource_summary'
                        label="Summary*"
                        placeholder="Edit section summary..."
                        type="text"
                        rows={10}
                        handleChange={this.handleTextChange}
                    />
                </div>

                <div className={classes.margin} />
                <div className={classes.margin} style={{ marginBottom: '1.5em' }} />

                <RadioGroup
                    label="Publication Status*"
                    onChange={this.handleRadioChange}
                    selectedValue={radio}
                >
                    <Radio 
                        label='Draft' 
                        value="isDraft" 
                    />
                    <Radio 
                        label='Published' 
                        value="isPublished" 
                        disabled={userCanPublish ? false : true}
                    />
                </RadioGroup>

                <Button icon='updated'
                    type="submit" 
                    disabled={!(resource_name || resource_short_name || resource_summary || radio)}
                    intent="success" 
                    text={`Save changes`} 
                />

                <Can I='delete' a='Category'>
                    <Button 
                        icon='delete'
                        className={classes.margin} 
                        intent="danger" 
                        text="Delete section"  
                        onClick={e => this.handleAlertIsOpen(e, 
                            !this.state.isAlertOpen)
                        }
                    />
                </Can>

                <Button icon='arrow-left'
                    className={classes.margin} 
                    name="default" 
                    intent="primary" 
                    text="Cancel" 
                    onClick={() => {
                        if (this.state.edit_section) {
                            this.setState({ edit_section: false, add_category: true })
                        }
                    }} 
                />
            </Fragment>
        );

    }

    handleAlertIsOpen = (e, toggledState) => {
        e.preventDefault()
        this.setState({ isAlertOpen: toggledState })
    }

    handleAlertConfirm = e => {
        e.preventDefault()
        if (this.state.edit_section) {
            // console.log(this.state)
        } else {
            // handle deletion of questions and close dialog box
            this.handleDeleteSection(e)
            this.handleAlertIsOpen(e, !this.state?.isAlertOpen)
        }
    }

    render() {
        const { classes, handleClick, userCanPublish } = this.props;
        const {
            _question, editorText, shortname, radio
        } = this.state;

        const emptyQFields = _question && editorText && shortname ? false : true;
        // Frequently asked question sections
        const sections = this.props.maincategory;

        const roles = Object.keys(UserProfile.get().roles)[0]

        return (
            <Fragment>
                <h5 className='heading-text'>
                    Create a new question or manage categories for different questions
                </h5>

                <ConfirmActionManager 
                    alertMessage={() => {
                        return <Fragment>
                            Are you sure you want to delete <b>{
                                `${ this.state?.section?.name }`
                            }</b>? You will not be able to restore this category of questions later.
                        </Fragment>
                    }}
                    cancelButtonText="Cancel deletion"
                    confirmButtonText="Confirm deletion"
                    isAlertOpen={this.state?.isAlertOpen} 
                    handleAlertCancel={e => this.handleAlertIsOpen(e, !this.state.isAlertOpen)}
                    handleAlertConfirm={this.handleAlertConfirm}
                />

                <form onSubmit={(e) => this.handleSubmit(e)} autoComplete="off">
                    <div className={classes.margin} />
                    <div className={classes.margin} />
                    {
                        (!this.state.add_section && !this.state.edit_section) ? (
                            <Fragment>
                                { /** filter sections here */}
                                <FormControl>
                                    <Paper elevation={0}>
                                        <SelectInputControl
                                            name="section"
                                            label='Choose category'
                                            {...this.state}
                                            onChange={e => this.handleChange(e)}
                                        >
                                            <option value="">{`---Category---`}</option>
                                            {
                                                sections?.subCategories.map(({ 
                                                    _id, name 
                                                }, index) => {
                                                    // section
                                                    return <option 
                                                        id={_id} 
                                                        key={`${index}`} 
                                                        value={name}
                                                    >
                                                        {name}
                                                    </option>
                                                })
                                            }
                                        </SelectInputControl>
                                    </Paper>
                                </FormControl>

                                { /** New frequently asked questions section */}
                                <Button
                                    icon='new-object'
                                    className={classes.margin}
                                    name="add_section"
                                    value={this.state.add_section}
                                    intent="primary" text="Create category"
                                    onClick={e => this.handleAddSection(e)}
                                />

                                {
                                    this.state?.section && (
                                        // show these buttons only when
                                        // an item is selected.
                                        !this.state?.add_category && (
                                            <>
                                            <Can I='update' a='Category'>
                                                <Button
                                                    icon='edit'
                                                    className={classes.margin}
                                                    name="edit_section"
                                                    value={this.state.edit_section}
                                                    intent="primary" text="Edit selected"
                                                    onClick={e => this.handleEditSection(e)}
                                                />
                                            </Can>
                                            <Can I='delete' a='Category'>
                                                <Button
                                                    icon='delete'
                                                    className={classes.margin}
                                                    name="delete_section"
                                                    value={this.state.section._id}
                                                    intent="danger" 
                                                    text="Delete selected"
                                                    onClick={e => this.handleAlertIsOpen(e, 
                                                        !this.state.isAlertOpen)
                                                    }
                                                />
                                            </Can>
                                        </>)
                                    )
                                }

                                <div className={classes.margin} />

                                <div className='margin-fix form-row'>
                                    <BootstrapGridColumn>
                                        <BootsrapTextField
                                            name="_question"
                                            value={this.state._question}
                                            label="Question*"
                                            type="text"
                                            placeholder="Enter new question here..."
                                            handleChange={this.handleTextChange}
                                        />
                                    </BootstrapGridColumn>
                                    <BootstrapGridColumn>
                                        <BootsrapTextField
                                            name="shortname"
                                            type="text"
                                            placeholder="Enter question shortname..."
                                            label="Shortname*"
                                            value={this.state.question}
                                            handleChange={this.handleTextChange}
                                        />
                                    </BootstrapGridColumn>
                                </div>

                                <CustomCKEditor
                                    editorText={this.state?.editorText}
                                    label="Summary*"
                                    setEditorText={this.setEditorText}
                                />

                                <div className={classes.margin} />
                                <div className={classes.margin} style={{ marginBottom: '1.5em' }} />

                                <RadioGroup
                                    label="Publication Status*"
                                    onChange={this.handleRadioChange}
                                    selectedValue={radio}
                                >
                                    <Radio 
                                        label='Draft' 
                                        value="isDraft" 
                                    />
                                    <Radio 
                                        label='Published' 
                                        value="isPublished" 
                                        disabled={roles !== 'writer' ? false : true}
                                    />
                                </RadioGroup>

                                <Button icon='new-object'
                                    type="submit" 
                                    disabled={emptyQFields}
                                    intent="success" text={`Save ${ this.state.add_section ? 'section' : 'question' }`} 
                                />

                                <Button icon='arrow-left'
                                    className={classes.margin} 
                                    name="default" 
                                    intent="primary" 
                                    text="Cancel" 
                                    onClick={e => handleClick(e)}
                                />
                            </Fragment>
                        ) : (
                            <Fragment>
                                {
                                    this.state.add_section && this.addSection()
                                }

                                {
                                    this.state.edit_section && this.editSection()
                                }
                            </Fragment>
                        )
                    }
                </form>
            </Fragment>
        );
    }
}

CreateQuestion.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default (withStyles(styles)(CreateQuestion));