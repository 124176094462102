import React, { useState } from 'react'
import { Card, Callout, Collapse, Elevation, Icon } from "@blueprintjs/core"

const button = {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderRadius: '0',
    background: '#BFCCD6',
    fontSize: '1.2em',
    padding: '10px',
    cursor: 'pointer'
}

const categoryContainer = {
    marginBottom: '10px',
    textAlign: 'left'
}
/**
 * Render question category
 * 
 * @author Isaac S. Mwakabira
 * 
 */
const QuestionCategory = ({ name, index, ...props }) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div style={categoryContainer}>
            <Callout 
                onClick={ (e) => setIsOpen(!isOpen) } 
                name={ `${ isOpen }` } 
                style={button} righticon="download">
                <div style={{ alignSelf: 'flex-start'}}>
                    { (index + 1) + '. ' + name }
                </div>
                <div style={{marginLeft: 'auto'}}>
                    { isOpen ? <Icon icon="remove"/> : <Icon icon="add"/>} 
                </div>
            </Callout>
            <Collapse isOpen={isOpen}>
                <Card interactive={true} elevation={Elevation.ZERO}>
                    { props.children }
                </Card>
            </Collapse>
        </div>
    )
}

export default QuestionCategory