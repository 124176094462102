import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { withStyles, Tooltip, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import styles from "../contact/form.styles";
import { ToolbarManager } from "../cms/common/toolbar.manager";
import { Can, useAuth } from "../user/access/user.permissions";
import { algorithms } from "../common/algorithms";

const VALUES = ["all"];

/**
 * List all licensing steps
 *
 * @author Isaac S. Mwakabira
 */
export const ListLicensing = withStyles(styles)(
  ({ classes, handleClick, maincategory, general }) => {
    const [filters, setFilters] = useState(VALUES);

    // authenticated user
    const auth = useAuth();

    return (
      <Fragment>
        <ToolbarManager
          filters={filters}
          setFilters={setFilters}
          handleClick={handleClick}
          classes={classes}
        >
          <Can I="create" a="Category">
            <Fragment>
              <Tooltip title="Create new licensing step">
                <Button name="create" onClick={(e) => handleClick(e)}>
                  <AddIcon /> New licensing step
                </Button>
              </Tooltip>
            </Fragment>
          </Can>
        </ToolbarManager>

        <ul>
          {!general?.isLoading ? (
            maincategory?.subCategories
              ?.filter((object) => algorithms.filterByUserAbility(object, auth))
              ?.filter((item) => algorithms.filterByProperty(filters, item))
              ?.map((category, index) => {
                return (
                  <li key={index}>
                    <Can I="update" a="Category">
                      <a
                        href={`${"/steps/" + category.name}`}
                        onClick={(e) => handleClick(e)}
                        name="edit"
                        id={category._id}
                      >
                        {category.name}
                      </a>
                    </Can>
                  </li>
                );
              })
          ) : (
            <div style={{ marginTop: `50px` }} className="loader" />
          )}
        </ul>
      </Fragment>
    );
  }
);

ListLicensing.propTypes = {
  classes: PropTypes.object.isRequired,
};
