import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Button, Radio, RadioGroup } from '@blueprintjs/core';
import styles from '../contact/form.styles';
import BootstrapGridColumn from '../forms/form.grid.column';
import { BootsrapTextField } from '../forms/form.bootstrap.field';
import CustomCKEditor from '../ckeditor/editor.component';
import { useAuth } from '../user/access/user.permissions';

/**
 * Add a new home subcategory
 * 
 * @author Isaac S. Mwakabira
 * 
 */
const CreateHomeSubcategory = ({
    category,
    createCategory,
    capitalize,
    classes, 
    handleClick, 
    link,
    userCanPublish
}) => {
    const [name, setName] = useState()
    const [shortname, setShortname] = useState()
    const [editorText, setEditorText] = useState('')
    const [radio, setRadio] = useState('isDraft')

    const auth = useAuth()
    const roles = Object.keys(auth?.user.roles)[0]

    const handleRadioChange = (e) => {
        setRadio(e.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // get authenticated user token
        if (auth?.user?.token) {
            let sub_category;
            if (name && shortname && editorText) {
                // define sub-category structure
                sub_category = {
                    name: capitalize(name),
                    shortName: shortname,
                    about: editorText,
                    isDraft: radio === 'isDraft' ? true : false,
                    isPublished: radio === 'isPublished' ? true : false
                }

                // category under which this subcategory should 
                // be uploaded to
                // home category exists
                if (category?.length !== 0 ) {
                    // proceeed to adding new subcategories under it
                    createCategory(
                        category._id, 
                        sub_category, 
                        auth?.user?.token,
                        capitalize(link)
                    );
                } else {
                    // creating new category
                    createCategory(
                        null, 
                        sub_category, 
                        auth?.user?.token,
                        capitalize(link)
                    );
                }
            }
        }
    }

    return (
        <Fragment>
            <h5 className='heading-text'>Create new item</h5>

            <div className={classes.margin} />
            <div className={classes.margin} />

            <form onSubmit={(e) => handleSubmit(e)} autoComplete="off">
                <div className="margin-fix form-row">
                    <BootstrapGridColumn>
                        <BootsrapTextField
                            value={name}
                            name='name'
                            label='Name*'
                            type='text'
                            placeholder='New section name...'
                            handleChange={e=>setName(e.target.value)}
                        />
                    </BootstrapGridColumn>
                    <BootstrapGridColumn>
                        <BootsrapTextField
                            value={shortname}
                            name='shortname'
                            label='Shortname*'
                            type='text'
                            placeholder='New section shortname...'
                            handleChange={e=>setShortname(e.target.value)}
                        />
                    </BootstrapGridColumn>
                </div>

                <CustomCKEditor
                    editorText={editorText}
                    label="Summary Text*"
                    setEditorText={editor => setEditorText(editor?.getData())}
                />

                <div className={classes.margin} />
                <div className={classes.margin} style={{ marginBottom: '1.5em' }}/>

                <RadioGroup
                    label="Publication Status"
                    onChange={handleRadioChange}
                    selectedValue={radio}
                >
                    <Radio 
                        label='Draft' 
                        value="isDraft" 
                    />
                    <Radio 
                        label='Published' 
                        value="isPublished"
                        disabled={roles !== 'writer' ? false : true}
                    />
                </RadioGroup>

                <Button icon='new-object'
                    type="submit" 
                    disabled={!(name && shortname && editorText)} 
                    intent="success" text="Save section" 
                />

                <Button icon='arrow-left'
                    className={classes.margin} 
                    name="default" intent="primary" 
                    text="Cancel" onClick={e => handleClick(e)} 
                />
            </form>
        </Fragment>
    );
}

CreateHomeSubcategory.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default (withStyles(styles)(CreateHomeSubcategory));